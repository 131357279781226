import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";

export default class PasswordField extends Component {
  constructor() {
    super();
    this.state = {
      showPassword: false
    };
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    let { title, ...otherProps } = this.props;
    let label = title || "Password";

    return (
      <FormControl fullWidth={true} margin="dense">
        <InputLabel htmlFor="adornment-password">{label}</InputLabel>
        <Input
          {...otherProps}
          label={label}
           
          autoComplete="current-password"
          type={this.state.showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }
}
