import React from "react";
import Spinner from "../controls/Spinner";

export default function LoadingContainer(props) {
  let { isLoading } = props;

  // TODO: @Nicole: Make spinner stay in container (mess with CSS)
  if (isLoading) {
    return <Spinner spinnerClass="button-spinner" />;
  }

  return props.children;
}
