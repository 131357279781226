import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import "../landing-page.css";
import Step from "../controls/Step";
import MainGraphic from "../controls/MainGraphic";
import Button from "@material-ui/core/Button";
import AnchorScroll from "../controls/Scrolls/AnchorScroll";
import { Link } from "react-router-dom";
import API from "../tools/api";
import ReactGA from "react-ga";

export default class NewLandingPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    ReactGA.pageview("landing");
  }

  render() {
    const AdapterLink = React.forwardRef((props, ref) => (
      <Link innerRef={ref} {...props} />
    ));

    return (
      <div>
        <div className="landing-page-main-section">
          <MainGraphic />

          <div
            style={{
              position: "absolute",
              bottom: "5vh",
              left: "50%",
              marginLeft: "-30px"
            }}
          >
            <AnchorScroll id="anchor-scroll" />
          </div>
        </div>

        <div className="steps-section">
          <Grid
            container
            direction="column"
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item xs={12}>
              <center>
                <div
                  style={{
                    width: "100%"
                  }}
                >
                  <h2 id="steps-section">
                    <center>Save money on your ride.</center>
                  </h2>
                </div>
              </center>
            </Grid>

            <Grid item xs={12}>
              <div className="row align-items-start steps-wrapper">
                <Step number="1" />

                <Step number="2" />

                <Step number="3" />

                <Step number="4" />
              </div>
            </Grid>

            {!API.IS_UNDER_CONSTRUCTION && (
              <Grid item xs={12} className="justify-content-center" style={{}}>
                <center>
                  <Button
                    color="primary"
                    variant="contained"
                    component={AdapterLink}
                    to="/post"
                    style={{
                      color: "white",
                      marginTop: "2em"
                    }}
                  >
                    <strong>Get Started</strong>
                  </Button>
                </center>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}
