import React, { createContext, useContext, useReducer } from "react";
export const StateContext = createContext();
export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);

export default StateContext;

/*
HOW TO USE AND CHANGE THE STATE IN THE APP:

Use in functional comp:

import { useStateValue } from './state';

const ThemedButton = () => {
  const [{ theme }, dispatch] = useStateValue();
  return (
    <Button
      primaryColor={theme.primary}
      onClick={() => dispatch({
        type: 'changeTheme',
        newTheme: { primary: 'blue'}
      })}
    >
      Make me blue!
    </Button>
  );
}

Use in Component:

import React, { Component } from 'react';
import { StateContext } from './state';
class ThemedButton extends Component {
  static contextType = StateContext;
  render() {
    const [{ theme }, dispatch] = this.context;
    return (
      <Button
        primaryColor={theme.primary}
        onClick={() => dispatch({
          type: 'changeTheme',
          newTheme: { primary: 'blue'}
        })}
      >
        Make me blue!
      </Button>
    );
  }
}
*/
