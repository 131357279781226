import React from "react";
//import scrollIcon from "../Icons/anchor-scroll-icon.svg";
import planeUp from "../../Icons/plane-up.svg";
import { Link } from "react-scroll";
import Icon from "@material-ui/core/Icon";

export default function ScrollToTop(props) {
  return (
    <Link activeClass="active" to="top" spy={true} smooth={true} duration={500}>
      <Icon>
        <img
          className="scroll-to-top chevron-icon"
          src={planeUp}
          alt="Scroll to top"
        />
      </Icon>
    </Link>
  );
}
