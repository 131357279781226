import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Spinner from "../controls/Spinner";
import api from "../tools/api";

import { StateContext } from "../StateContext";

export default class ConfirmEmail extends Component {
  static contextType = StateContext;

  constructor() {
    super();
    this.state = {
      confirmed: false,
      serverErrorMessage: null,
      user: null,
      loading: true
    };
  }

  handleError = error => {
    if (error !== null && error.hasOwnProperty("data")) {
      if (error.data.hasOwnProperty("Message")) {
        this.setState({ serverErrorMessage: error.data.Message });
      } else if (error.data.hasOwnProperty("msg")) {
        this.setState({ serverErrorMessage: error.data.msg });
      }
    } else {
      this.setState({
        serverErrorMessage: "The server encountered an error. Please try again."
      });
    }
  };

  componentDidMount = async () => {
    let key = this.props.match.params.token;

    const [{ user }, dispatch] = this.context;
    try {
      let authResponse = await api.confirmEmail(key);
      dispatch({
        type: "handleAuth",
        authResponse: authResponse
      });
      this.setState({ user: authResponse.user, confirmed: true });
    } catch (e) {
      this.handleError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const AdapterLink = React.forwardRef((props, ref) => (
      <Link innerRef={ref} {...props} />
    ));

    let result =
      this.state.serverErrorMessage === null && this.state.confirmed ? (
        <center>
          <div className="page-header">Email confirmed! </div>
          You are now logged in as{" "}
          <Link to="/profile">{this.state.user.name}</Link>
          <center>
            <Button
              color="primary"
              variant="contained"
              component={AdapterLink}
              to="/post"
              style={{
                color: "white",
                marginTop: "2em"
              }}
            >
              <strong>Get Started</strong>
            </Button>
          </center>
        </center>
      ) : (
        this.state.serverErrorMessage
      );

    return (
      <center>
        <Paper
          style={{ maxWidth: "400px", marginTop: "3rem", padding: "1rem" }}
        >
          <div style={{ minHeight: "20vh" }}>
            {this.state.loading ? (
              <Spinner spinnerClass="small-spinner-bottom" />
            ) : (
              result
            )}
          </div>
        </Paper>
      </center>
    );
  }
}
