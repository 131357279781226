import "./App.css";
import "./react-datetime.css";
import "typeface-roboto";

import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import API from "./tools/api";
import { setToken } from "./tools/axiosClient";

import auth from "./tools/auth";

import RDUAppBar from "./controls/BasePage/AppBar";
import UnderConstructionBar from "./controls/BasePage/UnderConstructionBar";
import BasePage from "./controls/BasePage/BasePage";
import ProtectedRoute from "./controls/BasePage/ProtectedRoute";
import RouterSwitch from "./controls/BasePage/RouterSwitch";
import { StateProvider } from "./StateContext";
import schoolManager from "./tools/schoolManager";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import { duke } from "./controls/schoolThemes";
const outerTheme = duke;

const themeOverride = createMuiTheme(outerTheme);

export default class App extends Component {
  constructor() {
    super();
    console.log("hey");
    const trackingId = "UA-153064051-1";
    ReactGA.initialize(trackingId);

    if (auth.isLoggedIn()) {
      ReactGA.set({
        userToken: auth.getJWT().token
      });
    }
  }

  render() {
    const IS_UNDER_CONSTRUCTION = API.IS_UNDER_CONSTRUCTION;
    const schoolFromCookie = schoolManager.getSchool() || null;
    const userFromCookie = auth.getUser() || null;
    const jwtFromCookie = auth.getJWT() || null;

    const initialState = {
      school: schoolFromCookie,
      user: userFromCookie,
      jwt: jwtFromCookie,
      loggedIn: userFromCookie !== null
    };

    if (jwtFromCookie) {
      setToken(jwtFromCookie.accessToken);
    }

    const reducer = (state, action) => {
      switch (action.type) {
        case "handleAuth": {
          if (action.authResponse && action.authResponse.jwt) {
            setToken(action.authResponse.jwt.accessToken);
          }

          return {
            ...state,
            user: action.authResponse.user,
            jwt: action.authResponse.jwt,
            loggedIn: action.authResponse.user !== null
          };
        }

        case "logout": {
          auth.logout();
          setToken("");
          return {
            ...state,
            user: null,
            jwt: null,
            loggedIn: false
          };
        }

        case "updateSchool": {
          schoolManager.setSchool(action.newSchool);
          return {
            ...state,
            school: action.newSchool
          };
        }

        default:
          return state;
      }
    };

    return (
      <StateProvider initialState={initialState} reducer={reducer}>
        <MuiThemeProvider theme={themeOverride}>
          <BrowserRouter>
            <BasePage>
              <div className="body-container">
                {IS_UNDER_CONSTRUCTION ? (
                  <UnderConstructionBar />
                ) : (
                  <RDUAppBar />
                )}
                <RouterSwitch />
              </div>
            </BasePage>
          </BrowserRouter>
        </MuiThemeProvider>
      </StateProvider>
    );
  }
}
