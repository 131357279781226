export const schools = ["Duke", "UNC", "NC State"];

export const subdomainNames = ["duke", "unc", "ncstate"];
//export const subdomainNames = ["duke"];

export const locations = {
  duke: ["East Campus", "Chapel Bus Stop", "Edens/Hollows", "300 Swift"],
  unc: ["unc1", "unc2", "unc3", "unc4"],
  ncstate: ["ncstate1", "ncstate2", "ncstate3", "ncstate4"]
};

export const dukeLocationsMap = {
  0: "East Campus",
  1: "Chapel Bus Stop",
  2: "Edens/Hollows",
  3: "300 Swift"
};

export const colors = {
  duke: "#003087",
  unc: "#4B9CD3",
  ncstate: "#CC0000"
};

export function getLocationsFor(school) {
  return locations[school];
}

export default {
  schools,
  dukeLocationsMap,
  subdomainNames,
  locations,
  colors,
  getLocationsFor
};
