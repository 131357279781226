import React from "react";
import "../../App.css";
import { Row, Col } from "reactstrap";

import * as formatter from "../../tools/formatters";

import API from "../../tools/api";
import * as Rules from "../../tools/rules";

import FormValidationComponent from "../../tools/FormValidationComponent";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";

import Spinner from "../Spinner";

import PasswordField from "./PasswordField";

import { withStyles } from "@material-ui/core/styles";

import { StateContext } from "../../StateContext";

import ReactGA from "react-ga";

const StyledButton = withStyles({
  label: {
    textTransform: "capitalize"
  }
})(Button);

export default class RegisterPopup extends FormValidationComponent {
  static contextType = StateContext;
  constructor() {
    super();
    this.state = {
      user: {},
      serverErrorMessage: null,
      emailInUseError: false,
      loading: false,
      success: false,
      validationErrors: {},
      authResponse: null,
      fieldValidations: [
        super.makeValidate("name", true),
        super.makeValidate("phoneNumber", false, [Rules.isPhoneNumber]),
        super.makeValidate("email", true, [Rules.isEmail]),
        super.makeValidate("password", true)
      ]
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // When the component is added, fetch the student and update state

  handleError = error => {
    if (error !== null && error.hasOwnProperty("data")) {
      if (error.data.hasOwnProperty("Message")) {
        this.setState({ serverErrorMessage: error.data.Message });
      } else if (error.data.hasOwnProperty("msg")) {
        this.setState({ serverErrorMessage: error.data.msg });
      }
    } else {
      this.setState({
        serverErrorMessage: "The server encountered an error. Please try again."
      });
    }
  };

  handleKeyDown = e => {
    //console.log(e);
  };

  closeRegister = () => {
    if (this.state.success) {
      const [{ user }, dispatch] = this.context;
      dispatch({
        type: "handleAuth",
        authResponse: this.state.authResponse
      });
    }
    this.props.closeHandler();
  };

  internalHandleChange = (key, value) => {
    var { user } = this.state;
    user[key] = value;
    this.setState({ user });
    if (this.state.showErrors) this.validateFields(this.state.user);
  };

  handleChange = evt => {
    this.internalHandleChange(evt.target.name, evt.target.value);
  };

  handlePhoneNumberChange = evt => {
    this.internalHandleChange(
      evt.target.name,
      formatter.phoneNumber(evt.target.value)
    );
  };

  register = async () => {
    const [{ user }, dispatch] = this.context;
    this.setState({ showErrors: true, success: false });
    let valid = this.validateFields(this.state.user);

    let localUser = this.state.user;

    if (valid) {
      this.setState({ loading: true });
      let didGetError = false;
      let authResponse;
      try {
        authResponse = await API.register(localUser);
        this.setState({ authResponse: authResponse });
      } catch (e) {
        didGetError = true;
        this.handleError(e);
      } finally {
        this.setState({ loading: false });
        if (!didGetError) {
          this.setState({ success: true });
        }
      }
    }
  };

  render() {
    if (this.props.open) {
      ReactGA.modalview("register");
    }
    let finalActionButtonsRow = (
      <DialogActions>
        <Button
          onClick={this.closeRegister}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button onClick={this.register} variant="outlined" color="primary">
          Register
        </Button>
      </DialogActions>
    );

    if (this.state.loading) {
      finalActionButtonsRow = (
        <center>
          <Spinner spinnerClass="small-spinner-bottom" />
        </center>
      );
    }

    let errorLabel = null;
    if (this.state.success) {
      errorLabel = (
        <Row className="my-2">
          <Col className="col-8 justify-content-center">
            <p className="text-center">
              {"A confirmation email was sent to " +
                this.state.user.email +
                ". For safety reasons, you must confirm before continuing."}
            </p>
          </Col>
        </Row>
      );
      finalActionButtonsRow = (
        <DialogActions>
          <Button
            onClick={this.closeRegister}
            variant="outlined"
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      );
    } else if (this.state.serverErrorMessage !== null && !this.state.success) {
      let errorMessage = this.state.serverErrorMessage;
      errorLabel = (
        <Row className="my-2">
          <Col className="col-12 justify-content-center">
            <p className="text-center error-label">{errorMessage}</p>
          </Col>
        </Row>
      );
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.closeRegister}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: "100%", maxWidth: "400px", padding: "5px" }}>
          <DialogTitle id="alert-dialog-title"> Register </DialogTitle>
          <DialogContent>
            <Row>
              <Col
                className="form-group col-12"
                style={{ marginBottom: "0px" }}
              >
                <DialogContentText>
                  {super.renderInput(
                    "name",
                    "Name",
                    <FormControl fullWidth={true} margin="dense">
                      <InputLabel>Name</InputLabel>
                      <Input
                        shrink
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Johnny Appleseed"
                        value={this.state.user.name || ""}
                        onChange={this.handleChange}
                        autoFocus
                        label="Name"
                        margin="dense"
                        inputProps={{ autoComplete: "name", tabIndex: 1 }}
                      />
                    </FormControl>
                  )}
                </DialogContentText>
              </Col>

              <Col
                className="form-group col-12"
                style={{ marginBottom: "0px" }}
              >
                <DialogContentText>
                  {this.renderInput(
                    "email",
                    "School Email",
                    <FormControl fullWidth={true} margin="dense">
                      <InputLabel>School Email</InputLabel>
                      <Input
                        shrink
                        type="text"
                        name="email"
                        id="email"
                        placeholder="vprice@duke.edu"
                        value={this.state.user.email || ""}
                        onChange={this.handleChange}
                        margin="dense"
                        label="Email"
                        fullWidth={true}
                        aria-describedby="email-helper-text"
                        inputProps={{ autoComplete: "email", tabIndex: 1 }}
                      />
                      <FormHelperText id="email-helper-text">
                        Must be a .edu email. We'll only match you with people
                        from your school.
                      </FormHelperText>
                    </FormControl>
                  )}
                </DialogContentText>
              </Col>
              <Col
                className="form-group col-12 "
                style={{ marginBottom: "0px" }}
              >
                <DialogContentText>
                  {this.renderInput(
                    "phoneNumber",
                    "Phone Number",
                    <FormControl fullWidth={true} margin="dense">
                      <InputLabel>Phone Number</InputLabel>
                      <Input
                        shrink
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="(760) 803-4582"
                        value={this.state.user.phoneNumber || ""}
                        onChange={this.handlePhoneNumberChange}
                        margin="dense"
                        label="Phone Number"
                        fullWidth={true}
                        aria-describedby="phone-helper-text"
                        inputProps={{ autoComplete: "tel", tabIndex: 1 }}
                      />
                      <FormHelperText id="phone-helper-text">
                        Optional, but helpful for communication
                      </FormHelperText>
                    </FormControl>
                  )}
                </DialogContentText>
              </Col>
              <Col
                className="form-group col-12"
                style={{ marginBottom: "0px" }}
              >
                <DialogContentText>
                  {this.renderInput(
                    "password",
                    "New Password",
                    <PasswordField
                      name="password"
                      id="password"
                      fullWidth
                      value={this.state.user.password || ""}
                      onChange={this.handleChange}
                    />
                  )}
                </DialogContentText>
              </Col>
            </Row>
            <Row>
              <StyledButton color="primary" onClick={this.props.switchToLogin}>
                Go to login
              </StyledButton>
            </Row>
          </DialogContent>
          {errorLabel}
        </div>
        {finalActionButtonsRow}
      </Dialog>
    );
  }
}
