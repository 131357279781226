import * as entry from "./endpointCollections/entryEndpoints";
import * as auth from "./endpointCollections/authEndpoints";
import * as feedback from "./endpointCollections/feedbackEndpoints";

export default {
  ...entry,
  ...auth,
  ...feedback,
  IS_UNDER_CONSTRUCTION: false
};
