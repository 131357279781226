import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import { duke } from "./schoolThemes";

const theme = duke;

export default class SchoolOption extends Component {
  constructor() {
    super();
    this.state = {
      hover: false
    };
  }

  handleMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  goToLink = () => {
    this.props.history.replaceState(this.props.path);
  };

  render() {
    let hoverStyle = {
      backgroundColor: this.props.color,
      filter: "brightness(190%)",
      textDecoration: "none",
      cursor: "pointer",
      top: "-3px"
    };
    let normalStyle = {
      backgroundColor: this.props.color,
      filter: "brightness(170%)"
    };

    return (
      <div className="card-container" onClick={this.props.linkAction}>
        <div
          className="card"
          style={this.state.hover ? hoverStyle : normalStyle}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <Typography
            variant="h5"
            style={{
              color: "#FFFFFF",
              textDecoration: "none"
            }}
          >
            {this.props.title}
          </Typography>
        </div>
      </div>
    );
  }
}
