import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import API from "../tools/api";
import Validator from "../tools/validator";

import moment from "moment-timezone";
import * as formatter from "../tools/formatters";
import TimeConfirmationPopup from "../controls/EditEntry/TimeConfirmationPopup";
import Button from "@material-ui/core/Button";
import BasePaper from "../controls/BasePage/BasePaper";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import DateTime from "react-datetime";
import DeleteControl from "../controls/EditEntry/DeleteControl";
import { Redirect } from "react-router";

import Spinner from "../controls/Spinner";

import auth from "../tools/auth";

import LoginAuthProvider from "../controls/auth/LoginAuthProvider";
import RegisterAuthProvider from "../controls/auth/RegisterAuthProvider";
import LoginWarning from "../controls/EditEntry/LoginWarning";

import Grid from "@material-ui/core/Grid";

import { StateContext } from "../StateContext";

import SchoolInfo from "../tools/schoolInfo";

import { Link } from "react-router-dom";
import withWindowDimensions from "../controls/BasePage/withWindowDimensions";

class EditEntry extends Component {
  static contextType = StateContext;
  // Instantiate state when the component is constructed
  constructor() {
    super();
    this.state = {
      entry: null,
      showErrors: false,
      validationErrors: {},
      serverErrorMessage: false,
      redirect: false,
      submitted: false,
      returned: false,
      createdID: null,
      showTimeConfirmation: false,
      tabState: 0,
      snackbarOpen: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleDirectionChange = (event, value) => {
    let { entry } = this.state;
    entry.direction = value;
    this.setState({ entry: entry });
  };

  handleTabChange = (event, value) => {
    this.setState({ tabState: value });
  };

  validateFields = () => {
    let entryObj = this.state.entry;

    let validator = new Validator(entryObj);

    validator.validate("tripName", false);
    validator.validate("startTime", true);
    validator.validate("endTime", true);
    validator.validate("origins", true);
    validator.validate("direction", true);

    let errorsDict = validator.errorsDict;
    this.setState({ validationErrors: errorsDict });

    return Object.keys(errorsDict).length === 0;
  };

  validateFieldsForDelete = () => {
    let entryObj = this.state.entry;

    let validator = new Validator(entryObj);

    let errorsDict = validator.errorsDict;
    this.setState({ validationErrors: errorsDict });

    return Object.keys(errorsDict).length === 0;
  };

  showError = field => {
    return (
      this.state.validationErrors.hasOwnProperty(field) && this.state.showErrors
    );
  };

  reloadUser = async () => {
    const [{ user }, dispatch] = this.context;
    try {
      let authResponse = await API.refreshUser();
      dispatch({
        type: "handleAuth",
        authResponse: authResponse
      });
    } catch (err) {
      //this.handleError(err);
    }
  };

  // When the component is added, fetch the student and update state
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.reloadUser();
    if (!this.props.isCreating) {
      try {
        let entry = await API.getEntry(this.props.match.params.id);
        this.setState({ entry: entry });
      } catch (err) {
        this.handleError(err);
      }
    } else {
      let originalStartTime = moment()
        .startOf("day")
        .add(31, "h");

      this.setState({
        entry: {
          origins: "",
          direction: 0,
          startTime: originalStartTime.unix(), // tomorrow at 7am
          endTime: originalStartTime.add(1, "h").unix() // 20 minutes after start time
        }
      });
    }
  }

  submit = async () => {
    this.setState({ showErrors: true });
    let valid = this.validateFields();

    if (valid) {
      // confirm that unordinarily large time windows aren't a mistake
      let duration = moment.duration(
        (this.state.entry.endTime - this.state.entry.startTime) * 1000
      );

      if (duration.asHours() < 0.5 || duration.asHours() > 4) {
        this.setState({ showTimeConfirmation: true });
      } else {
        this.executeSubmit();
      }
    }
  };

  executeSubmit = async () => {
    this.setState({ submitted: true });
    this.setState({ returned: false });

    if (this.props.isCreating) {
      try {
        let entry = await API.createEntry(this.state.entry);
        this.setState({ createdID: entry.id });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.setState({ returned: true });
      }
    } else {
      try {
        let entry = await API.updateEntry(
          this.state.entry.id,
          this.state.entry
        );
        this.setState({ createdID: entry.id });
      } catch (err) {
        this.handleError(err);
        //this.props.history.goBack();
      } finally {
        this.setState({ returned: true });
      }
    }
  };

  handleError = error => {
    if (error !== null && error.hasOwnProperty("data")) {
      if (error.data.hasOwnProperty("Message")) {
        this.setState({ serverErrorMessage: error.data.Message });
      } else if (error.data.hasOwnProperty("msg")) {
        this.setState({ serverErrorMessage: error.data.msg });
        if (error.data.msg == "Missing Authorization Header") {
          this.setState({ serverErrorMessage: "Please login to add a trip" });
        }
      } else if (error.status === 401 || error.status === 422) {
        this.setState({ serverErrorMessage: "Please login to add a trip" });
      }
    } else {
      this.setState({
        serverErrorMessage: "The server encountered an error. Please try again."
      });
    }
  };

  delete = async () => {
    this.setState({ showErrors: true });
    let valid = this.validateFieldsForDelete();

    if (valid) {
      this.setState({ submitted: true });
      this.setState({ returned: false });
      try {
        let deletedEntry = await API.deleteEntry(
          this.state.entry.id,
          this.state.entry.password
        );
        this.props.history.goBack();
      } catch (err) {
        this.handleError(err);
      } finally {
        this.setState({ returned: true });
      }
    }
  };

  cancel = () => {
    this.props.history.goBack();
  };

  internalHandleChange = (key, value) => {
    const [{ user, loggedIn }, dispatch] = this.context;

    if (!loggedIn) {
      this.setState({ snackbarOpen: true });
    }

    var entryObj = this.state.entry;
    entryObj[key] = value;
    this.setState({ entry: entryObj });
    if (this.state.showErrors) this.validateFields();
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  sThatDay = datetime => {
    let datetimeMoment = moment.unix(datetime);
    if (datetimeMoment.isValid()) {
      let s = datetimeMoment.unix();
      let sPerDay = 86400;
      let beginning = s - (s % sPerDay);

      return s - beginning;
    } else {
      return 0;
    }
  };

  handleChange = evt => {
    this.internalHandleChange(evt.target.name, evt.target.value);
  };

  handlePhoneNumberChange = evt => {
    this.internalHandleChange(
      evt.target.name,
      formatter.phoneNumber(evt.target.value)
    );
  };

  handleDateChange = date => {
    let unixDate = date.tz("America/New_York").unix();
    let sPerDay = 86400;
    let beginning = unixDate - (unixDate % sPerDay);

    let startSecondsThatDay = this.sThatDay(this.state.entry.startTime);
    let newStart = beginning + startSecondsThatDay;

    let endSecondsThatDay = this.sThatDay(this.state.entry.endTime);
    let newEnd = beginning + endSecondsThatDay;

    this.internalHandleChange("startTime", newStart);
    this.internalHandleChange("endTime", newEnd);
  };

  handleStartTimeChange = startTime => {
    if (startTime instanceof moment) {
      this.internalHandleChange(
        "startTime",
        startTime.tz("America/New_York").unix()
      );
      if (startTime.isAfter(moment.unix(this.state.entry.endTime))) {
        let newEndTime = startTime.add(15, "minutes");
        this.handleEndTimeChange(newEndTime);
      }
    }
  };

  handleEndTimeChange = endTime => {
    if (endTime instanceof moment) {
      if (endTime.isAfter(moment.unix(this.state.entry.startTime))) {
        this.internalHandleChange(
          "endTime",
          endTime.tz("America/New_York").unix()
        );
      } else {
        this.internalHandleChange("endTime", this.state.entry.endTime);
      }
    }
  };

  handleOriginsChange = evt => {
    let value = evt.target.id;

    let originsString = this.state.entry.origins;
    var origins = originsString.split(",");

    var index = origins.indexOf(value);
    if (index > -1) {
      origins.splice(index, 1);
    } else {
      origins.push(value);
    }
    var newOriginsString = origins.join(",");
    if (newOriginsString.charAt(0) === ",") {
      newOriginsString = newOriginsString.substr(1);
    }
    this.internalHandleChange("origins", newOriginsString);
  };

  originsIncludes = origin => {
    let originsString = this.state.entry.origins;
    var origins = originsString.split(",");
    var index = origins.indexOf(origin);
    return index > -1;
  };

  renderInput = (inputId, inputName, inputField, description = "") => {
    let descriptionLabel;
    if (description.length > 0) {
      descriptionLabel = <label htmlFor={inputId}>{description}</label>;
    } else {
      descriptionLabel = null;
    }

    return (
      <div>
        <span>
          <strong>{inputName}</strong>
        </span>
        <div>
          <small className="text-muted">{descriptionLabel}</small>
        </div>
        <center>{inputField}</center>
        <label
          className="error-label"
          htmlFor={inputId}
          hidden={!this.showError(inputId)}
        >
          {this.state.validationErrors[inputId]}
        </label>
      </div>
    );
  };

  componentDidUpdate() {
    var $ = require("jquery");
    $(".rdtPicker").addClass("mx-auto");
  }

  isFutureDate = function(current, selected) {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  isValidEndTime = function(currentDate, selectedDate) {
    let startTime = moment.unix(this.state.entry.startTime);
    return currentDate.isAfter(startTime);
  };

  render() {
    if (this.state.createdID !== null) {
      let route = "/matches/" + this.state.createdID.toString();
      return <Redirect push to={route} />;
    }

    let width = this.props.windowWidth;

    const [{ user, loggedIn }, dispatch] = this.context;

    let originsOuterStyle;
    let tabsClass = "";
    let originsInnerStyle = {
      margin: -5,
      float: "left",
      position: "relative",
      right: "-50%"
    };

    if (width < 768) {
      originsOuterStyle = {
        float: "left",
        position: "relative",
        right: "50%"
      };
      tabsClass = "origins-tabs";
    } else {
      originsOuterStyle = {
        float: "left",
        position: "relative",
        right: "80%",
        marginLeft: -5
      };
    }

    var entry = this.state.entry;

    let origins = SchoolInfo.dukeLocationsMap;

    if (entry == null && this.state.encounteredServerError) {
      return (
        <center className="mt-5" style={{ color: "#ff0000" }}>
          <b>
            <small>Server encountered an error. Please try again later.</small>
          </b>
        </center>
      );
    }

    if (entry != null) {
      const originOptions = Object.keys(origins).map(key => {
        return (
          <div style={originsOuterStyle}>
            <FormControlLabel
              style={originsInnerStyle}
              key={key}
              control={
                <Checkbox
                  id={key}
                  name={origins[key]}
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={this.originsIncludes(key)}
                  onChange={this.handleOriginsChange}
                  value={origins[key]}
                  color={
                    this.state.entry.direction === 0 ? "primary" : "secondary"
                  }
                  style={{ display: "relative", left: "0" }}
                />
              }
              label={origins[key]}
            />
          </div>
        );
      });

      let deleteControl;
      if (this.props.isCreating) {
        deleteControl = null;
      } else {
        deleteControl = (
          <DeleteControl title="Delete Entry" onClickAction={this.delete} />
        );
      }

      let startTime = moment.unix(entry.startTime);
      let endTime = moment.unix(entry.endTime);

      let buttonColors = {
        save: "#0736a4"
      };

      let errorLabel = null;
      var errorText = "";
      if (Object.keys(this.state.validationErrors).length !== 0) {
        errorText += "Some fields are invalid.";
      }

      if (this.state.serverErrorMessage !== null) {
        errorText = this.state.serverErrorMessage;
      }
      if (errorText.length !== 0) {
        errorLabel = (
          <Row className="my-2">
            <Col className="col-12 justify-content-center">
              <p className="text-center error-label">{errorText}</p>
            </Col>
          </Row>
        );
      }

      let finalActionButtonsRow = (
        <center>
          {deleteControl}
          <Button
            variant="contained"
            onClick={this.cancel}
            style={{ margin: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: buttonColors.save }}
            onClick={this.submit}
          >
            <div style={{ color: "#FFFFFF" }}>Save</div>
          </Button>
        </center>
      );

      if (this.state.submitted && !this.state.returned) {
        finalActionButtonsRow = (
          <Row className style={{ margin: "0rem" }}>
            <Col className="col-12 justify-content-center">
              <Spinner spinnerClass="small-spinner" />
            </Col>
          </Row>
        );
      }

      const [{ user }, dispatch] = this.context;
      let authRowContent = null;
      if (user !== null && !user.confirmed) {
        authRowContent = (
          <Col className="form-group col-12 text-center">
            You must confirm your email before adding a trip. Request a new
            email from <Link to="/profile">profile</Link>
          </Col>
        );
      } else {
        authRowContent =
          user !== null ? (
            <Col className="form-group col-12 text-center">
              Logged in as <Link to="/profile">{user.name}</Link>
            </Col>
          ) : (
            <div style={{ marginBottom: "2rem" }}>
              <div style={{ marginBottom: "1rem" }}>
                <b>You must log in or register to post.</b>
              </div>
              <Grid container justify="center" spacing={3}>
                <Grid item>
                  <LoginAuthProvider>
                    <Button variant="outlined" color="secondary">
                      Login
                    </Button>
                  </LoginAuthProvider>
                </Grid>
                {this.props.isCreating && (
                  <Grid item>
                    <RegisterAuthProvider>
                      <Button variant="outlined" color="primary">
                        Register
                      </Button>
                    </RegisterAuthProvider>
                  </Grid>
                )}
              </Grid>
            </div>
          );
      }

      return (
        <Container
          style={{
            overflowX: "hidden"
          }}
        >
          <BasePaper>
            <div style={{ marginBottom: "2em" }}>
              <center>
                <div className="page-header">Create a Post</div>
              </center>
              <LoginWarning
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
              />

              <TimeConfirmationPopup
                open={this.state.showTimeConfirmation}
                closeHandler={() => {
                  this.setState({ showTimeConfirmation: false });
                }}
                confirmHandler={() => {
                  this.setState({ showTimeConfirmation: false });
                  this.executeSubmit();
                }}
                startTime={this.state.entry.startTime}
                endTime={this.state.entry.endTime}
              />
              <Row className="my-2 justify-content-center">
                {authRowContent}
              </Row>

              <Row className="my-2 align-items-start">
                <Col className="form-group col-12 col-md-5">
                  <div>
                    {this.renderInput(
                      "origins",
                      "Pick-up/Drop-off location",
                      <div>
                        <center>
                          <Tabs
                            value={this.state.entry.direction || 0}
                            indicatorColor={
                              this.state.entry.direction === 1
                                ? "secondary"
                                : "primary"
                            }
                            textColor={
                              this.state.entry.direction === 1
                                ? "secondary"
                                : "primary"
                            }
                            onChange={this.handleDirectionChange}
                            className={tabsClass}
                          >
                            <Tab label="Duke → RDU" className="disable-focus" />
                            <Tab label="RDU → Duke" className="disable-focus" />
                          </Tabs>
                        </center>
                        <FormControl component="fieldset">
                          <FormGroup style={{ marginTop: "0.5rem" }}>
                            {originOptions}
                          </FormGroup>
                        </FormControl>
                      </div>,
                      "Select multiple to increase your chance of finding a match"
                    )}
                  </div>
                </Col>
                <Col className="form-group col-12 col-md-5">
                  {this.renderInput(
                    "date",
                    "Date",
                    <DateTime
                      classname="align-middle"
                      name="date"
                      displayTimeZone="America/New_York"
                      id="date"
                      isValidDate={this.isFutureDate}
                      timeFormat={false}
                      input={false}
                      value={startTime.isValid() ? startTime : null}
                      onChange={this.handleDateChange}
                    />,
                    "Day you're headed to the airport"
                  )}
                </Col>
              </Row>
              <Row className="my-2" />
              <Row className="my-2">
                <Col className="form-group col-12 col-md-5">
                  {this.renderInput(
                    "startTime",
                    "Earliest time",
                    <DateTime
                      name="startTime"
                      displayTimeZone="America/New_York"
                      id="startTime"
                      timeConstraints={{ minutes: { step: 5 } }}
                      dateFormat={false}
                      input={false}
                      value={startTime.isValid() ? startTime : null}
                      onChange={this.handleStartTimeChange}
                    />,
                    "Earliest time you're willing to leave"
                  )}
                </Col>
                <Col className="form-group col-12 col-md-5">
                  {this.renderInput(
                    "endTime",
                    "Latest time",
                    <DateTime
                      disabled={!loggedIn}
                      classname="align-middle"
                      displayTimeZone="America/New_York"
                      name="endTime"
                      id="endTime"
                      timeConstraints={{ minutes: { step: 5 } }}
                      dateFormat={false}
                      input={false}
                      value={endTime.isValid() ? endTime : null}
                      onChange={this.handleEndTimeChange}
                    />,
                    "Latest time you're willing to leave"
                  )}
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="form-group col-12 col-md-8" />
              </Row>
              {errorLabel}
              {finalActionButtonsRow}
            </div>
          </BasePaper>
        </Container>
      );
    } else {
      return null;
    }
  }
}

export default withWindowDimensions(EditEntry);
