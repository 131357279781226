import React, { Component } from "react";
import AccordionEntryCell from "./AccordionCell";
import NewAccordionCell from "./NewAccordionCell";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

export default function AccordionSection(props) {
  const classes = useStyles();
  let { entries, day } = props;

  // const accordionCells = entries.map(entry => {
  //   return <AccordionEntryCell key={entry.id} entry={entry} />;
  // });

  const accordionCells = entries.map(entry => {
    return <NewAccordionCell key={entry.id} entry={entry} />;
  });

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{day}</Typography>
        <Typography
          style={{ marginRight: "0", marginLeft: "auto" }}
          className={classes.secondaryHeading}
        >
          {entries.length + (entries.length === 1 ? " post" : " posts")}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
        >
          {accordionCells}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
