import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import SchoolInfo from "../../tools/schoolInfo";
import { useStateValue } from "../../StateContext";

export default function SchoolSpecificRoute({
  component: Component,
  componentProps,
  pathTemplate,
  ...rest
}) {
  const [{ school: currentSchool }, dispatch] = useStateValue();

  //let schoolSubdomains = SchoolInfo.subdomainNames;
  let schoolSubdomains = ["duke"];
  return schoolSubdomains.map(school => {
    const completedPath = pathTemplate.replace("*school*", school);
    return (
      <Route
        exact
        key={school.toString()}
        path={completedPath}
        render={props => {
          if (currentSchool !== school) {
            dispatch({
              type: "updateSchool",
              newSchool: school
            });
          }
          return <Component {...props} {...componentProps} />;
        }}
      />
    );
  });
}
