import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Exit from "@material-ui/icons/ExitToApp";
import Edit from "@material-ui/icons/Edit";
import Group from "@material-ui/icons/Group";
import AddBox from "@material-ui/icons/AddBox";
import MenuList from "@material-ui/core/MenuList";
import Menu from "@material-ui/core/Menu";
import AccountIcon from "@material-ui/icons/AccountCircle";
import LoginAuthProvider from "../auth/LoginAuthProvider";
import RegisterAuthProvider from "../auth/RegisterAuthProvider";
import { useStateValue } from "../../StateContext";

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [{ user }, dispatch] = useStateValue();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  let loggedInOptions = (
    <MenuList>
      <div>
        <Link
          to="/profile"
          style={{ textDecoration: "none", color: "#000000", marginLeft: "0" }}
        >
          <MenuItem>
            <ListItemIcon>
              <AccountIcon />
            </ListItemIcon>
            <ListItemText
              primary="Profile"
              onClick={() => {
                handleClose();
              }}
            />
          </MenuItem>
        </Link>
      </div>
      <div>
        <Link
          to="/myposts"
          style={{ textDecoration: "none", color: "#000000" }}
        >
          <MenuItem>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText
              primary="My Posts"
              onClick={() => {
                handleClose();
              }}
            />
          </MenuItem>
        </Link>
      </div>

      <Divider />
      <MenuItem>
        <ListItemIcon>
          <Exit />
        </ListItemIcon>
        <ListItemText
          primary="Logout"
          onClick={() => {
            dispatch({
              type: "logout"
            });
            handleClose();
          }}
        />
      </MenuItem>
    </MenuList>
  );

  let loggedOutOptions = (
    <MenuList>
      <LoginAuthProvider>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <ListItemIcon>
            <Exit />
          </ListItemIcon>
          <ListItemText primary="Log in" />
        </MenuItem>
      </LoginAuthProvider>
      <RegisterAuthProvider>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <ListItemIcon>
            <AddBox />
          </ListItemIcon>
          <ListItemText primary="Register" />
        </MenuItem>
      </RegisterAuthProvider>
    </MenuList>
  );

  return (
    <div>
      <IconButton
        aria-haspopup={true}
        color="inherit"
        aria-label="Account"
        onClick={handleClick}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {user !== null ? loggedInOptions : loggedOutOptions}
      </Menu>
    </div>
  );
}
