import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import API from "../../tools/api";

//import Auth from "../../pages/Auth";
import AllEntries from "../../pages/AllEntries";
import ShowMatches from "../../pages/ShowMatches";
import EditEntry from "../../pages/EditEntry";
import OldLandingPage from "../../pages/OldLandingPage";
import PickSchool from "../../pages/PickSchool";
import PageNotFound from "../../pages/PageNotFound";
import ResetPassword from "../../pages/ResetPassword";
import LandingPage from "../../pages/LandingPage";
import Profile from "../../pages/Profile";
import NewEditEntry from "../../pages/NewEditEntry";
import MyPosts from "../../pages/MyPosts";
import ConfirmEmail from "../../pages/ConfirmEmail";

import RDUAppBar from "./AppBar";
import UnderConstructionBar from "./UnderConstructionBar";
import ProtectedRoute from "./ProtectedRoute";
import SchoolSpecificRoute from "./SchoolSpecificRoute";
import SchoolRequiredRoute from "./SchoolRequiredRoute";

export default function RouterSwitch(props) {
  const IS_UNDER_CONSTRUCTION = API.IS_UNDER_CONSTRUCTION;
  return (
    <Switch>
      {IS_UNDER_CONSTRUCTION && <Route component={LandingPage} />}
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/reset/:resetKey" component={ResetPassword} />
      <Route exact path="/pickschool" component={PickSchool} />
      {SchoolSpecificRoute({
        pathTemplate: "/*school*",
        component: AllEntries
      })}
      {SchoolSpecificRoute({
        pathTemplate: "/*school*/add",
        component: EditEntry,
        componentProps: { isCreating: true }
      })}
      <SchoolRequiredRoute exact path="/posts" pathTemplate="/*school*" />
      <SchoolRequiredRoute exact path="/post" pathTemplate="/*school*/add" />
      <Route exact path="/matches/:id" component={ShowMatches} />
      <Route
        exact
        path="/edit/:id"
        render={props => <EditEntry {...props} isCreating={false} />}
      />
      <ProtectedRoute exact path="/profile" component={Profile} />
      <ProtectedRoute exact path="/myposts" component={MyPosts} />
      <Route exact path="/timemachine" component={OldLandingPage} />
      <Route exact path="/confirm/:token" component={ConfirmEmail} />
      <Route component={PageNotFound} />
      <Route
        exact
        path="/neweditentry"
        render={props => <NewEditEntry {...props} isCreating={true} />}
      />
    </Switch>
  );
}
