import moment from "moment-timezone";

export function formatPhoneNumber(phoneNumber) {
  const input = phoneNumber.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${zip}) ${middle}-${last}`;
  } else if (input.length > 3) {
    return `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    return `(${zip}`;
  } else {
    return phoneNumber;
  }
}

export function formatTimeSpan(startTimeUnix, endTimeUnix, separator = " – ") {
  let startTime = moment
    .unix(startTimeUnix)
    .tz("America/New_York")
    .format("h:mm a");

  let endTime = moment
    .unix(endTimeUnix)
    .tz("America/New_York")
    .format("h:mm a");

  return startTime + separator + endTime;
}
