import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export default class PageNotFound extends Component {
  render() {
    return (
      <div style={{ padding: "5%", maxWidth: "1600px", margin: "auto" }}>
        <Typography component="h3" variant="h5" gutterBottom>
          <strong>Yikes...</strong>
          <br />
          404 - Page Not Found
        </Typography>

        <br />

        <div className="row">
          <div className="col-2"> </div>
          <div className="col-7">
            <Typography variant="h6" gutterBottom>
              <center>
                Select one of our developers to be publicly humiliated for this
                fatal flaw.
              </center>
            </Typography>
          </div>
          <div className="col-2"> </div>
        </div>

        <br />

        <div className="row">
          <div className="col-6 text-center">
            <img
              src={require("../Icons/yasa-error-404.png")}
              alt="Yasa"
              className="yasa-portrait center-block"
            />
          </div>
          <div className="col-6 text-center">
            <img
              src={require("../Icons/cooper-error-404.png")}
              alt="Cooper"
              className="cooper-portrait center-block"
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: "1em", marginBottom: "1em" }}>
          <div className="col-6 text-center">
            <Button variant="contained" color="primary">
              <div>Yasa</div>
            </Button>
          </div>
          <div className="col-6 text-center">
            <Button variant="contained" color="primary">
              <div>Cooper</div>
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <Typography variant="h4">...or:</Typography>
          </div>
        </div>

        <br />

        <div className="row">
          <Button variant="contained" color="primary">
            <div>
              have mercy on these poor
              <br />
              sleep-deprived souls
            </div>
          </Button>
        </div>
      </div>
    );
  }
}
