import React, { Component } from "react";
//import auth from "../tools/auth";
import TextField from "@material-ui/core/TextField";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";

import * as Rules from "../tools/rules";
import * as formatter from "../tools/formatters";

import { StateContext } from "../StateContext";

export default class EditProfileInfo extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = { name: "", email: "", phoneNumber: "" };
  }

  componentDidMount() {
    const [{ loggedIn, user }, dispatch] = this.context;
    if (loggedIn) {
      let { name, email, phoneNumber } = user;
      this.setState({ name: name, email: email, phoneNumber: phoneNumber });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handlePhoneNumberChange = evt => {
    let formattedPhoneNumber = formatter.phoneNumber(evt.target.value);
    this.setState({ phoneNumber: formattedPhoneNumber });
  };

  render() {
    let user = this.state;
    let isPhoneNumberValid =
      user.phoneNumber === "" || Rules.isPhoneNumber(user.phoneNumber) === null;
    return (
      <div value={user} onChange={this.props.onChange}>
        <TextField
          label="Name"
          name="name"
          margin="normal"
          placeholder="Johnny Appleseed"
          value={user.name}
          onChange={this.handleChange}
          fullWidth={true}
          error={user.name === ""}
          helperText={user.name === "" ? "Name cannot be blank" : null}
        />
        <TextField
          label="Email"
          name="email"
          margin="normal"
          disabled
          variant="filled"
          placeholder="vprice@duke.edu"
          value={user.email}
          onChange={this.handleChange}
          fullWidth={true}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          label="Phone Number"
          name="phoneNumber"
          margin="normal"
          placeholder="(760) 803-4582"
          value={user.phoneNumber}
          onChange={this.handlePhoneNumberChange}
          fullWidth={true}
          error={!isPhoneNumberValid}
          helperText={isPhoneNumberValid ? null : "Invalid phone number"}
        />
      </div>
    );
  }
}
