import React, { Component } from "react";
import NewAccordionCell from "../controls/Posts/NewAccordionCell";
import API from "../tools/api";
import { Container, Row } from "reactstrap";
import Spinner from "../controls/Spinner";
import Grid from "@material-ui/core/Grid";

export default class ShowMatches extends Component {
  // Instantiate state when the component is constructed
  constructor() {
    super();
    this.state = {
      entries: [],
      retrieved: false,
      encounteredServerError: false
    };
  }

  // When the component is added, fetch the entries and update state
  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      let entries = await API.getMatches(this.props.match.params.id);
      this.setState({ entries: entries });
    } catch (e) {
      this.setState({ encounteredServerError: true });
    } finally {
      this.setState({ retrieved: true });
    }
  }

  render() {
    let { entries } = this.state;
    const accordionCells = Object.keys(entries).map((key, index) => (
      <NewAccordionCell key={entries[index].id} entry={entries[index]} />
    ));

    if (!this.state.retrieved) {
      return <Spinner spinnerClass="big-spinner" />;
    } else {
      if (this.state.encounteredServerError) {
        return (
          <center className="mt-5" style={{ color: "#ff0000" }}>
            <b>
              <small>
                Server encountered an error. Please try again later.
              </small>
            </b>
          </center>
        );
      } else if (accordionCells.length === 0) {
        return (
          <big>
            <b>
              <center className="mt-5" style={{ color: "#000000" }}>
                No matches yet, but we'll email you when you get matches
              </center>
            </b>
          </big>
        );
      } else {
        return (
          <div>
            <center className="mt-5" style={{ color: "#000000" }}>
              <center>
                <div className="page-header">Your Matches!</div>
              </center>
            </center>
            <Grid container justify="center" spacing={3}>
              {accordionCells}
            </Grid>
          </div>
        );
      }
    }
  }
}
