import React, { Component } from "react";
import Validator from "../tools/validator";

export default class EditEntry extends Component {
  validateFields(obj) {
    let validator = new Validator(obj);

    this.state.fieldValidations.forEach(validation => {
      validator.validate(
        validation.field,
        validation.required,
        validation.rules
      );
    });

    let errorsDict = validator.errorsDict;
    this.setState({ validationErrors: errorsDict });

    return Object.keys(errorsDict).length === 0;
  }

  showError(field) {
    return (
      this.state.validationErrors.hasOwnProperty(field) && this.state.showErrors
    );
  }

  makeValidate(field, required, rules = []) {
    return { field: field, required: required, rules: rules };
  }

  renderInput(inputId, inputName, inputField, description = "") {
    return (
      <div>
        {inputField}
        <label
          className="error-label"
          htmlFor={inputId}
          hidden={!this.showError(inputId)}
        >
          {this.state.validationErrors[inputId]}
        </label>
      </div>
    );
  }
}
