import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Warning from "@material-ui/icons/Warning";
import SvgIcon from "@material-ui/core/SvgIcon";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

export default class UnderConstructionBar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const location = window.location.pathname;

    const classicAppBar = (
      <AppBar
        id="app-bar"
        color="secondary"
        position={location !== "/feedback" ? "fixed" : "sticky"}
        elevation="10"
        style={{ marginBottom: "20px", padding: "1em" }}
      >
        <center>
          <h4>
            <SvgIcon
              style={{ display: "inline-block", paddingBottom: "0.1em" }}
            >
              <Warning />
            </SvgIcon>{" "}
            Under construction{" "}
            <SvgIcon
              style={{ display: "inline-block", paddingBottom: "0.1em" }}
            >
              <Warning />
            </SvgIcon>
          </h4>
          Please check back soon for the new and improved MeYouRDU.
        </center>
      </AppBar>
    );

    return <ElevationScroll>{classicAppBar}</ElevationScroll>;
  }
}
