var cookie = require("react-cookies");

export function getSchool() {
  return cookie.load("school");
}

export function setSchool(school) {
  cookie.save("school", school, {
    path: "/"
  });
}

export default { getSchool, setSchool };
