import React, { Component } from "react";

import API from "../tools/api";

import moment from "moment-timezone";

import Grid from "@material-ui/core/Grid";

import DateSelection from "../controls/EditEntry/DateSelection";
import DirectionSelection from "../controls/EditEntry/DirectionSelection";
import TimeSelection from "../controls/EditEntry/TimeSelection";

export default class NewEditEntry extends Component {
  state = {
    entry: null,
    showErrors: false,
    validationErrors: {},
    encounteredServerError: false,
    redirect: false,
    submitted: false,
    returned: false,
    createdID: null,
    showTimeConfirmation: false,
    tabState: 0,
    authJWT: ""
  };

  handleError = error => {
    if (error != null) {
      if (error.status === 401) {
        this.setState({ incorrectPassword: true });
      } else {
        this.setState({ encounteredServerError: true });
      }
    }
  };

  handleChange = (event, value) => {
    this.setState({ entry: { [event.target.name]: event.target.value } });
  };

  render() {
    let { entry } = this.state;

    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={3}
        style={{
          overflowX: "hidden"
        }}
      >
        <Grid item xs={12}>
          <center>
            <div className="page-header">Create a Post</div>
          </center>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DirectionSelection name="direction" onChange={this.handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateSelection name="date" onChange={this.handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TimeSelection
            name="startTime"
            onChange={this.handleChange}
            type="start"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TimeSelection
            name="endTime"
            onChange={this.handleChange}
            type="end"
          />
        </Grid>
      </Grid>
    );
  }
}
