import React from "react";
import Footer from "./Footer";
import ScrollToTop from "../Scrolls/ScrollToTop";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import withWindowDimensions from "./withWindowDimensions";

function BasePaper(props) {
  let width = props.windowWidth;

  if (width <= 460) {
    return <div> {props.children} </div>;
  } else {
    return (
      <Grid container justify="center" style={{ height: "100%" }}>
        <Grid item xs={12} sm={11} md={9} lg={8}>
          <Paper style={{ padding: "1rem", margin: "2rem 0" }}>
            {props.children}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withWindowDimensions(BasePaper);
