import React, { Component } from "react";
import "../landing-page.css";
import form from "../Icons/new-icons/form.svg";
import match from "../Icons/new-icons/match.svg";
import mail from "../Icons/new-icons/mail.svg";
import car from "../Icons/new-icons/car.svg";
import gold from "../Icons/pot of gold.svg";

const stepIconDict = {
  "1": form,
  "2": match,
  "3": mail,
  "4": car
};

const titles = {
  1: "Fill out the form",
  2: "Get matched",
  3: "Check your email",
  4: "Contact your matches"
};

const descriptions = {
  1: "with pick-up time and location",
  2: "to potential carpoolers",
  3: "for notification of new matches",
  4: "to coordinate your Lyft/Uber"
};

export default function Step({ number: stepNumber }) {
  let stepIcon = stepIconDict[stepNumber];
  let title = titles[stepNumber];
  let description = descriptions[stepNumber];

  return (
    <div className="col-sm-12 col-md-6 col-lg-3 step">
      <center>
        <div className="dot">
          <div className="dot-number center-block">{stepNumber}</div>
        </div>
        <img src={stepIcon} alt={"Step " + stepNumber} className="step-icon" />
      </center>
      <center />
      <br />
      <div>
        <h4 className="no-padding">
          <b>
            <center>{title}</center>
          </b>
        </h4>
        <h6 className="no-padding">
          <center>{description}</center>
        </h6>
      </div>
    </div>
  );
}
