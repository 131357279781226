import React, { Component } from "react";
import AccordionSection from "../controls/Posts/AccordionSection";
import moment from "moment-timezone";
import API from "../tools/api";
import NetworkDataDisplay from "../controls/NetworkDataDisplay";
import auth from "../tools/auth";

export default class AllEntries extends Component {
  constructor() {
    super();
    this.state = {
      entriesMap: new Map(),
      isLoading: true,
      errorMessage: null
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    try {
      let entries = await API.getEntriesForCurrentUser();
      let filteredEntries = entries.filter(entry => {
        let endTime = moment.unix(entry.endTime);
        return endTime.diff(moment(), "days") > -1;
      });
      this.setState({
        errorMessage: null,
        entriesMap: this.makeEntriesMap(filteredEntries)
      });
    } catch (err) {
      this.setState({ errorMessage: err.message });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  // Given array of entries, create map where key is date
  // and value is an array of entries on that date
  makeEntriesMap = entries => {
    let map = new Map();
    entries.forEach(entry => {
      let day = moment
        .unix(entry.startTime)
        .tz("America/New_York")
        .format("dddd, MMMM Do");
      if (!map.has(day)) map.set(day, []);
      var postsOnDay = map.get(day);
      postsOnDay.push(entry);
    });
    return map;
  };

  render() {
    let { entriesMap, isLoading, errorMessage } = this.state;

    return (
      <div>
        <center>
          <div className="page-header" style={{ marginTop: "2rem" }}>
            My Posts
          </div>
        </center>
        <NetworkDataDisplay isLoading={isLoading} serverError={errorMessage}>
          {entriesMap.size === 0 ? (
            <center className="mt-5">
              <b>
                <big>No entries yet</big>
              </b>
            </center>
          ) : (
            <div>
              <center className="subtitle">
                Browse your posts to edit them or view matches.
              </center>
              <div className="accordion" id="accordionExample">
                {Array.from(entriesMap, ([date, entries]) => {
                  return (
                    <AccordionSection key={date} entries={entries} day={date} />
                  );
                })}
              </div>
            </div>
          )}
        </NetworkDataDisplay>
      </div>
    );
  }
}
