import React, { Component, Fragment } from "react";

import API from "../../tools/api";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import LoadingContainer from "../LoadingContainer";

import ReactGA from "react-ga";

import { withStyles } from "@material-ui/core/styles";

const StyledButton = withStyles({
  label: {
    textTransform: "capitalize"
  }
})(Button);

export default class ForgotPasswordPopup extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      showEmailError: false,
      errorMessage: null,
      isLoading: false,
      success: false
    };
  }

  handleError = error => {
    if (error.status === 401) {
      this.setState({ errorMessage: "Incorrect credentials" });
    } else {
      this.setState({ errorMessage: "The server encountered an error" });
    }
  };

  submit = async () => {
    let { email } = this.state;

    if (email.length === 0) {
      this.setState({ showEmailError: true });
      return;
    }

    this.setState({ isLoading: true, showEmailError: false });

    try {
      let response = await API.requestPasswordReset(this.state.email);
      this.setState({ errorMessage: null, success: true });
    } catch (e) {
      this.handleError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    if (this.props.open) {
      console.log("FORGOT");
      ReactGA.modalview("forgot password");
    }
    let { isLoading, errorMessage, email, success } = this.state;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Forgot Password</DialogTitle>
        <DialogContent>
          {success ? (
            <div>{"A password reset link has been sent to " + email}</div>
          ) : (
            <Fragment>
              We'll email you a link to the password reset page.
              <TextField
                autoFocus
                id="standard-required"
                fullWidth
                label="Email"
                value={this.state.email}
                onChange={evt => {
                  this.setState({ email: evt.target.value });
                }}
                error={this.state.showEmailError}
                inputProps={{ autoComplete: "email" }}
              />
              {!isLoading && errorMessage != null && <div>{errorMessage}</div>}
              {isLoading && <div>Loading</div>}
            </Fragment>
          )}
        </DialogContent>
        {!success && (
          <DialogActions>
            <Button onClick={this.props.switchToLogin} color="secondary">
              Back
            </Button>
            <LoadingContainer isLoading={isLoading}>
              <Button onClick={this.submit} color="primary" autoFocus>
                Submit
              </Button>
            </LoadingContainer>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}
