import auth from "../../tools/auth";
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { useStateValue } from "../../StateContext";

export default function ProtectedRoute({ component: Component, ...rest }) {
  const [{ user }, dispatch] = useStateValue();
  return (
    <Route
      {...rest}
      render={props =>
        user !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
