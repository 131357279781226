import api from "../axiosClient";

var moment = require("moment-timezone");

function formatDateRange(startTime, endTime) {
  let startMoment = moment.unix(startTime);
  let endMoment = moment.unix(endTime);

  var dateString = "on ";
  dateString += startMoment.tz("America/New_York").format("dddd, MMMM Do");
  dateString += " between ";
  dateString += startMoment.tz("America/New_York").format("h:mm A");
  dateString += " and ";
  dateString += endMoment.tz("America/New_York").format("h:mm A");
  return dateString;
}

export async function getAllEntries() {
  let { data } = await api.get("entries");
  return data;
}

// Get future entries
export async function getFutureEntries() {
  return await this.entriesInRange(moment().unix(), -1);
}

// Get entries after date
export async function getEntriesAfter(startTime) {
  return await this.entriesInRange(startTime, -1);
}

// Get entries before date
export async function getEntriesBefore(endTime) {
  return await this.entriesInRange(-1, endTime);
}

// Get entries in range
export async function entriesInRange(startTime, endTime) {
  var params = {};

  if (startTime !== -1) params.earliestTime = startTime;
  if (endTime !== -1) params.latestTime = endTime;

  let { data } = await api.get("entries/time", { params: params });
  return data;
}

// Get entries that match with the given entry
export async function getMatches(id) {
  let { data } = await api.get("entries/match/" + id);
  return data;
}

// Get entries posted by the logged-in user
export async function getEntriesForCurrentUser() {
  let { data } = await api.get("/user/entries");
  return data;
}

// Get entry with given id
export async function getEntry(id) {
  let { data } = await api.get("/entry/" + id);
  return data;
}

export async function createEntry(entryObj) {
  let clonedEntry = JSON.parse(JSON.stringify(entryObj));
  if (!clonedEntry.hasOwnProperty("tripName")) clonedEntry.tripName = "";
  let formattedDate = formatDateRange(
    clonedEntry.startTime,
    clonedEntry.endTime
  );

  clonedEntry.prettyDateString = formattedDate;

  clonedEntry.startTime = parseFloat(clonedEntry.startTime);
  clonedEntry.endTime = parseFloat(clonedEntry.endTime);

  let { data } = await api.post("entries/register/me", clonedEntry);
  return data;
}

export async function updateEntry(id, entryObj) {
  let clonedEntry = JSON.parse(JSON.stringify(entryObj));

  let formattedDate = formatDateRange(
    clonedEntry.startTime,
    clonedEntry.endTime
  );

  clonedEntry.prettyDateString = formattedDate;

  clonedEntry.startTime = parseFloat(clonedEntry.startTime);
  clonedEntry.endTime = parseFloat(clonedEntry.endTime);

  let { data } = await api.put("entry/" + id, clonedEntry);
  return data;
}

export async function deleteEntry(id, password) {
  let { data } = await api.delete("entry/" + id);
  return data;
}
