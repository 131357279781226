import auth from "../../tools/auth";
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { useStateValue } from "../../StateContext";

export default function SchoolRequiredRoute({
  component: Component,
  pathTemplate,
  ...rest
}) {
  const [{ school }, dispatch] = useStateValue();
  return (
    <Route
      {...rest}
      render={props => {
        if (school !== null) {
          const completePath = pathTemplate.replace("*school*", school);
          return (
            <Redirect
              to={{
                pathname: completePath
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/pickschool",
                state: { pathTemplate: pathTemplate }
              }}
            />
          );
        }
      }}
    />
  );
}
