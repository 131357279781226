import React, { Component } from "react";
import AccordionSection from "../controls/Posts/AccordionSection";
import moment from "moment-timezone";
import API from "../tools/api";
import NetworkDataDisplay from "../controls/NetworkDataDisplay";
import LoginAuthProvider from "../controls/auth/LoginAuthProvider";
import { Link } from "react-router-dom";
import { StateContext } from "../StateContext";

export default class AllEntries extends Component {
  static contextType = StateContext;
  constructor() {
    super();
    this.state = {
      entriesMap: new Map(),
      isLoading: true,
      errorMessage: null
    };
  }

  handleError = error => {
    let errorMsg = null;
    if (error) {
      if (error.hasOwnProperty("data") && error.data) {
        if (error.data.hasOwnProperty("Message") && error.data.Message) {
          errorMsg = error.data.Message;
        }
      }
    }
    if (errorMsg === null) {
      errorMsg = "Server error";
    }
    this.setState({ errorMessage: errorMsg });
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    try {
      let entries = await API.getFutureEntries();
      this.setState({
        errorMessage: null,
        entriesMap: this.makeEntriesMap(entries)
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  // Given array of entries, create map where key is date
  // and value is an array of entries on that date
  makeEntriesMap = entries => {
    let map = new Map();
    entries.forEach(entry => {
      let day = moment
        .unix(entry.startTime)
        .tz("America/New_York")
        .format("dddd, MMMM Do");
      if (!map.has(day)) map.set(day, []);
      var postsOnDay = map.get(day);
      postsOnDay.push(entry);
    });
    return map;
  };

  render() {
    let { entriesMap, isLoading, errorMessage } = this.state;

    const [{ loggedIn, user }, dispatch] = this.context;
    let confirmed = false;
    if (loggedIn) {
      confirmed = user.confirmed;
    }

    let contactInfoPrompt = "";

    if (!loggedIn) {
      contactInfoPrompt = (
        <div style={{ margin: "0.6rem 0 1rem 0" }}>
          <LoginAuthProvider>
            <span className="link">Log in</span>
          </LoginAuthProvider>{" "}
          to view other users' contact info.
        </div>
      );
    } else if (!confirmed) {
      contactInfoPrompt = (
        <div style={{ margin: "0.6rem 0 1rem 0" }}>
          <Link to="/profile">
            <span className="link">Confirm your email</span>
          </Link>{" "}
          to view other users' contact info.
        </div>
      );
    }

    return (
      <div>
        <center>
          <div className="page-header">All Posts</div>
          {contactInfoPrompt}
        </center>
        <NetworkDataDisplay isLoading={isLoading} serverError={errorMessage}>
          {entriesMap.size === 0 ? (
            <center className="mt-5">
              <b>
                <big>No entries yet</big>
              </b>
            </center>
          ) : (
            <div className="accordion" id="accordionExample">
              {Array.from(entriesMap, ([date, entries]) => {
                return (
                  <AccordionSection key={date} entries={entries} day={date} />
                );
              })}
            </div>
          )}
        </NetworkDataDisplay>
      </div>
    );
  }
}
