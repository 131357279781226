import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import Octicon from "react-octicon";
import { NavLink } from "react-router-dom";
import { formatPhoneNumber, formatTimeSpan } from "../../tools/utils";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from "@material-ui/core/Grid";

import TakeoffIcon from "@material-ui/icons/FlightTakeoff";
import LandingIcon from "@material-ui/icons/FlightLand";
import PersonIcon from "@material-ui/icons/AccountCircleRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import PhoneIcon from "@material-ui/icons/CallRounded";
import LocationIcon from "@material-ui/icons/NearMeRounded";
import MatchesIcon from "@material-ui/icons/PeopleRounded";
import EditIcon from "@material-ui/icons/EditRounded";
import auth from "../../tools/auth";
import SchoolInfo from "../../tools/schoolInfo";

import { Link } from "react-router-dom";

import { StateContext } from "../../StateContext";

import { duke } from "../schoolThemes";

const theme = duke;

export default class NewAccordionCell extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    // TODO: Get origins for school from backend
    this.state = {
      origins: SchoolInfo.dukeLocationsMap
    };
  }

  render() {
    let { origins } = this.state;
    let { entry } = this.props;

    if (entry == null) return null;

    let name = entry.name;
    let email = entry.email;

    let timeString = formatTimeSpan(entry.startTime, entry.endTime);

    const originKeysArray = entry.origins.split(",");
    const locationsArray = originKeysArray
      .map(i => origins[i])
      .filter(location => location != null);

    let locationsString =
      (entry.direction === 0 ? "From " : "To ") + locationsArray.join(", ");

    let phoneString = formatPhoneNumber(entry.phoneNumber);

    const [{ loggedIn, user }, dispatch] = this.context;
    let confirmed = false;
    if (loggedIn) {
      confirmed = user.confirmed;
    }

    let isOwner = loggedIn && entry.user.id === user.id;

    let contactInfo = { name: "", email: "", phoneNumber: "" };

    const redactedText = {
      name: <div className="blurred-text"> Lorem Ipsum </div>,
      email: <div className="blurred-text"> lorem.ipsum@duke.edu </div>,
      phoneNumber: <div className="blurred-text">000-000-0000</div>
    };

    if (!loggedIn || (loggedIn && !confirmed)) {
      contactInfo = {
        name: redactedText.name,
        email: redactedText.email,
        phoneNumber: redactedText.phoneNumber
      };
    } else {
      contactInfo = {
        name: name,
        email: (
          <a className="align-middle" color="link" href={"mailto:" + email}>
            <span>{email}</span>
          </a>
        ),
        phoneNumber: (
          <a
            className="align-middle"
            color="link"
            href={"tel: +1 " + phoneString}
          >
            <span>{phoneString}</span>
          </a>
        )
      };
    }

    return (
      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        style={{ maxWidth: "350px", minWidth: "300px" }}
      >
        <Card>
          <CardHeader
            style={{
              backgroundColor:
                entry.direction === 0
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
              color: "#FFFFFF"
            }}
            avatar={
              <div style={{ marginTop: -5 }}>
                {entry.direction === 0 ? <TakeoffIcon /> : <LandingIcon />}
              </div>
            }
            title={
              <div
                style={{
                  fontSize: "1.3rem",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif"
                }}
              >
                {timeString}
              </div>
            }
          />
          <CardContent>
            <Grid container direction="row">
              <Grid item xs={2}>
                <center>
                  <PersonIcon />
                </center>
              </Grid>
              <Grid item xs={10}>
                {contactInfo.name}{" "}
              </Grid>
              <Grid item xs={2}>
                <center>
                  <LocationIcon />
                </center>
              </Grid>
              <Grid item xs={10}>
                {locationsString}
              </Grid>
              <Grid item xs={2}>
                <center>
                  <EmailIcon />
                </center>
              </Grid>
              <Grid item xs={10}>
                {contactInfo.email}
              </Grid>
              {phoneString !== "" && (
                <Fragment>
                  <Grid item xs={2}>
                    <center>
                      <PhoneIcon />
                    </center>
                  </Grid>
                  <Grid item xs={10}>
                    {contactInfo.phoneNumber}
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </CardContent>

          {isOwner && (
            <CardActions disableSpacing style={{ float: "right" }}>
              <Link
                to={"/edit/" + entry.id}
                style={{
                  color: "#FFFFFF",
                  paddingRight: "5px"
                }}
              >
                <IconButton aria-label="edit post">
                  <EditIcon />
                </IconButton>
              </Link>
              <Link
                to={"/matches/" + entry.id}
                style={{
                  color: "#FFFFFF",
                  paddingRight: "5px"
                }}
              >
                <IconButton aria-label="view matches">
                  <MatchesIcon />
                </IconButton>
              </Link>
            </CardActions>
          )}
        </Card>
      </Grid>
    );
  }
}
