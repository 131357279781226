export function phoneNumber(phoneNumberString) {
  const input = phoneNumberString.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${zip}) ${middle}-${last}`;
  } else if (input.length > 3) {
    return `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    return `(${zip}`;
  } else {
    return phoneNumberString;
  }
}
