import React, { Component } from "react";
import { Redirect } from "react-router";
import "../landing-page.css";
import Typography from "@material-ui/core/Typography";
import "../App.css";
import SchoolOption from "../controls/SchoolOption";
import DisabledSchoolOption from "../controls/DisabledSchoolOption";
import SchoolInfo from "../tools/schoolInfo";
import { StateContext } from "../StateContext";

let schools = {
  duke: {
    main: "#003087",
    title: "Duke"
  },
  unc: {
    main: "#4B9CD3",
    title: "UNC Chapel Hill"
  },
  ncstate: {
    main: "#CC0000",
    title: "NC State"
  }
};

export default class PickSchool extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const [obj, dispatch] = this.context;
    return (
      <center>
        <center>
          <div className="page-header">Select your school below.</div>
        </center>
        <div style={{ margin: "1rem 2rem 2rem 2rem" }}>
          <div className="card-container">
            {SchoolInfo.subdomainNames.map(school => {
              const schoolObj = schools[school];

              let pathTemplate;
              try {
                pathTemplate = this.props.location.state.pathTemplate;
              } catch (e) {
                pathTemplate = "/*school*";
              }

              const completePath = pathTemplate.replace("*school*", school);

              if (
                schoolObj.title == "UNC Chapel Hill" ||
                schoolObj.title == "NC State"
              ) {
                return (
                  <DisabledSchoolOption
                    key={school.toString()}
                    color={schoolObj.main}
                    title={schoolObj.title}
                  />
                );
              }
              return (
                <SchoolOption
                  key={school.toString()}
                  color={schoolObj.main}
                  title={schoolObj.title}
                  linkAction={() => {
                    this.props.history.replace(completePath);
                  }}
                />
              );
            })}
          </div>
        </div>
      </center>
    );
  }
}
