import auth from "./auth";
import { StateContext } from "../StateContext";
var axios = require("axios");

// Instantiate an axios client
let client = axios.create({
  baseURL: `https://meyourdu.herokuapp.com/`
});

// Log passed data for non get requests
client.interceptors.request.use(
  function(config) {
    if (config.method !== "get") {
      // console.log("Data to send:");
      // console.log(config.data);
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export function setToken(token) {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //console.log(client.defaults);
}

// Add auth header to requests
client.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Print response and error messages
client.interceptors.response.use(
  function(response) {
    // console.log("response:");
    // console.log(response);
    return response;
  },
  function(error) {
    // console.log(error);
    return Promise.reject(error.response);
  }
);

export default client;
