import React, { Component } from "react";
import API from "../tools/api";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import ProfileInfo from "../controls/ProfileInfo";
import EditProfileInfo from "../controls/EditProfileInfo";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";

import LoginAuthProvider from "../controls/auth/LoginAuthProvider";
import RegisterAuthProvider from "../controls/auth/RegisterAuthProvider";

import Grid from "@material-ui/core/Grid";

import { StateContext } from "../StateContext";

import * as formatter from "../tools/formatters";
import FormValidationComponent from "../tools/FormValidationComponent";
import * as Rules from "../tools/rules";

export default class Profile extends FormValidationComponent {
  static contextType = StateContext;

  constructor() {
    super();
    this.state = {
      editing: false,
      shouldShowError: false,
      profileInfo: { name: "", email: "", phoneNumber: "" },
      editingInfo: { name: "", email: "", phoneNumber: "" },
      serverErrorMessage: null
    };
  }

  // The component is mounted and state is initialized with current user info.
  componentDidMount = async () => {
    this.reloadUser();
    this.loadUser();
  };

  reloadUser = async () => {
    const [{ user }, dispatch] = this.context;
    try {
      let authResponse = await API.refreshUser();
      dispatch({
        type: "handleAuth",
        authResponse: authResponse
      });
    } catch (err) {
      //this.handleError(err);
    }
  };

  handleError = error => {
    if (error !== null && error.hasOwnProperty("data")) {
      if (error.data.hasOwnProperty("Message")) {
        this.setState({ serverErrorMessage: error.data.Message });
      } else if (error.data.hasOwnProperty("msg")) {
        this.setState({ serverErrorMessage: error.data.msg });
      }
    } else {
      this.setState({
        serverErrorMessage: "The server encountered an error. Please try again."
      });
    }
  };

  loadUser = () => {
    const [{ loggedIn, user }, dispatch] = this.context;
    if (loggedIn) {
      this.setState({
        profileInfo: user
      });
    }
  };

  // Sets state to editing. Initializes editingInfo as currently stored user info
  handleEditClick = () => {
    this.setState({
      editing: !this.state.editing,
      editingInfo: this.state.profileInfo
    });
  };

  // The user submits the updated profile info.
  // TODO: Check validations, then render errors if invalid.
  // Otherwise submit changes.
  handleSubmit = async () => {
    let info = this.state.editingInfo;
    this.setState({ shouldShowError: true });
    let gotError = false;
    if (
      (info.phoneNumber !== "" &&
        Rules.isPhoneNumber(info.phoneNumber) !== null) ||
      info.name === ""
    ) {
      return;
    }

    try {
      const [{ user }, dispatch] = this.context;
      let authResponse = await API.updateProfile(info);
      dispatch({
        type: "handleAuth",
        authResponse: authResponse
      });
    } catch (err) {
      console.log(err);
      this.handleError(err);
      gotError = true;
    } finally {
      if (!gotError) this.setState({ editing: !this.state.editing });
    }
  };

  // The user edits one or more of his info fields. State is updated with the remaining
  // (unedited) fields maintained as their previous values.
  handleEditInfo = e => {
    const { name, value } = e.target;
    let { editingInfo } = this.state;
    editingInfo[name] = value;
    this.setState({
      editingInfo: editingInfo
    });
  };

  handleCancel = e => {
    this.setState({ editing: !this.state.editing });
  };

  // TODO: add tooltip to edit and save icons
  render() {
    let buttonColors = {
      save: "#0736a4"
    };

    let errorLabel = null;
    if (this.state.serverErrorMessage !== null) {
      errorLabel = <div>{this.state.serverErrorMessage}</div>;
    }

    let actionButtons = this.state.editing ? (
      <center>
        <Button
          variant="contained"
          onClick={this.handleCancel}
          style={{ margin: "1rem" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: buttonColors.save }}
          onClick={this.handleSubmit}
        >
          <div style={{ color: "#FFFFFF" }}>Save</div>
        </Button>
      </center>
    ) : (
      ""
    );

    let editButton = this.state.editing ? (
      ""
    ) : (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Cancel"
        onClick={this.handleEditClick}
        style={{
          zIndex: "1",
          display: "flex",
          position: "absolute",
          right: "0rem",
          top: "0",
          margin: "0.5rem"
        }}
      >
        {" "}
        <EditIcon />{" "}
      </IconButton>
    );

    const [{ loggedIn, user }, dispatch] = this.context;

    return (
      <Grid container justify="center">
        <Grid item xs={12} sm={9} md={6} lg={5} alignItems="center">
          <Paper
            style={{
              padding: "1rem",
              position: "relative",
              margin: "2rem"
            }}
          >
            <center>
              <h4>{this.state.editing ? "Edit Profile" : "Profile"}</h4>
            </center>

            {editButton}

            {this.state.editing ? (
              <EditProfileInfo onChange={this.handleEditInfo} />
            ) : (
              <ProfileInfo user={user} />
            )}
            {actionButtons}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
