import React, { Component } from "react";

export default function Spinner(props) {
  return (
    <div className={props.spinnerClass}>
      <img
        src={require("../Icons/Duke/spinner-circle-blue.svg")}
        className="circle"
        alt=""
      />
      <img
        src={require("../Icons/spinner-plane.svg")}
        className="plane spinner"
        alt="loading"
      />
    </div>
  );
}
