import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { Link } from "react-router-dom";
import ProfileButton from "./ProfileButton";

// import { duke } from "../schoolThemes";
// const theme = duke;

const schoolStrings = {
  duke: "Duke",
  unc: "UNC",
  ncstate: "NC State"
};

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

export default class RDUAppBar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    /*When we actually have routes established, we'll use this line:*/
    const location = window.location.pathname;

    /* Examples to show what the schoolPerspective will look like */
    // const location = "/duke/new";

    //  route possibilities:  "/duke", "/duke/add", "/", "/profile", "/pickschool", "/forgot"

    /* if this is a school specific route, element at index 1 of this array
    should be one of the schools we have */
    const locationArray = location.split("/");

    /* if so, the school is index 1 of the array. if not, the schoolPerspective
    won't display */
    const school = locationArray[1];
    const schoolSpecific =
      Object.keys(schoolStrings).indexOf(school) === -1 ? false : true;

    let schoolString = schoolStrings[school];

    const classicAppBar = (
      <AppBar
        id="app-bar"
        color="secondary"
        position={location === "/" ? "fixed" : "sticky"}
        elevation={10}
        style={{ marginBottom: "" }}
      >
        <Toolbar>
          <Link
            to="/"
            style={{
              color: "#FFFFFF",
              paddingRight: "5px"
            }}
          >
            <IconButton edge="start" color="inherit" aria-label="Home" to="/">
              <HomeIcon />
            </IconButton>
          </Link>

          <div
            style={{
              padding: "0px 20px",
              paddingTop: "8px",
              paddingLeft: "20px"
            }}
            className="appbar-link"
          >
            <Link
              to="/posts"
              style={{
                color: "#FFFFFF"
              }}
              className="appbar-link"
            >
              <h4> Posts </h4>
            </Link>
          </div>
          <div
            style={{
              paddingRight: "20px",
              paddingTop: "8px",
              paddingLeft: "20px"
            }}
            className="appbar-link"
          >
            <Link
              to="/post"
              style={{
                color: "#FFFFFF"
              }}
              className="appbar-link"
            >
              <h4> Add Trip </h4>
            </Link>
          </div>
          <div style={{ margin: "0 0 0 auto" }}>
            <ProfileButton />
          </div>
        </Toolbar>
      </AppBar>
    );

    const landingPageAppBar = (
      <ElevationScroll>{classicAppBar}</ElevationScroll>
    );

    const schoolPerspective = (
      <div style={{ padding: "5px" }}>
        Your school is {schoolString}. {"  "}
        <Link to="/pickschool">Change This</Link>
      </div>
    );

    return (
      <div>
        {location === "/" ? landingPageAppBar : classicAppBar}
        {schoolSpecific ? schoolPerspective : ""}
      </div>
    );
  }
}
