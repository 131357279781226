import React from "react";
import Spinner from "../controls/Spinner";

export default function NetworkDataDisplay(props) {
  let { isLoading, errorMessage } = props;

  if (isLoading) {
    return <Spinner spinnerClass="big-spinner" />;
  }

  if (errorMessage != null) {
    return (
      <center className="mt-5">
        <b>
          <big>{errorMessage}</big>
        </b>
      </center>
    );
  }

  return props.children;
}
