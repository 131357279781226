import React, { Component, Fragment } from "react";

import cookie from "react-cookies";

import LoginPopup from "./LoginPopup";
import RegisterPopup from "./RegisterPopup";
import ForgotPasswordPopup from "./ForgotPasswordPopup";

export default class AuthenticationProvider extends Component {
  // Instantiate state when the component is constructed
  constructor() {
    super();
    this.state = {
      openLogin: false,
      openRegister: false,
      openForgotPassword: false,
      authComplete: false
    };
  }

  openLogin = () => {
    this.setState({ openLogin: true });
    this.setState({ openRegister: false });
    this.setState({ openForgotPassword: false });
  };
  closeLogin = () => {
    this.setState({ openLogin: false });
  };

  openRegister = () => {
    this.setState({ openLogin: false });
    this.setState({ openRegister: true });
    this.setState({ openForgotPassword: false });
  };
  closeRegister = () => {
    this.setState({ openRegister: false });
  };

  openForgotPassword = () => {
    this.setState({ openLogin: false });
    this.setState({ openRegister: false });
    this.setState({ openForgotPassword: true });
  };
  closeForgotPassword = () => {
    this.setState({ openForgotPassword: false });
  };

  openPopup = type => {
    switch (type) {
      case "login":
        return this.openLogin;
        break;

      default:
        return this.openRegister;
        break;
    }
  };

  render() {
    let { openLogin, openRegister, openForgotPassword } = this.state;
    let { authComplete } = this.state;

    return (
      <Fragment>
        <LoginPopup
          open={openLogin}
          closeHandler={this.closeLogin}
          switchToRegister={this.openRegister}
          switchToForgotPassword={this.openForgotPassword}
        />
        <RegisterPopup
          open={openRegister}
          closeHandler={this.closeRegister}
          switchToLogin={this.openLogin}
        />
        <ForgotPasswordPopup
          open={openForgotPassword}
          closeHandler={this.closeForgotPassword}
          switchToLogin={this.openLogin}
        />
        <a onClick={this.openPopup(this.props.type)}>{this.props.children}</a>
      </Fragment>
    );
  }
}
