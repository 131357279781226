import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import moment from "moment-timezone";
import Octicon from "react-octicon";
import { NavLink } from "react-router-dom";
import * as formatter from "../../tools/formatters";
import Paper from "@material-ui/core/Paper";
import SchoolInfo from "../../tools/schoolInfo";

export default class AccordionCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      origins: SchoolInfo.dukeLocationsMap
    };
  }

  render() {
    let { origins } = this.state;
    let { entry } = this.props;

    if (entry != null) {
      let name = entry.name;
      let startTime = moment
        .unix(entry.startTime)
        .tz("America/New_York")
        .format("h:mm A");
      let endTime = moment
        .unix(entry.endTime)
        .tz("America/New_York")
        .format("h:mm A");
      let timeString = startTime + " - " + endTime;
      let originsArray = entry.origins.split(",");
      let locationsArray = [];
      for (var i = 0; i < originsArray.length; i++) {
        if (origins[originsArray[i]] != null) {
          locationsArray.push(origins[originsArray[i]]);
        }
      }

      let locationsString =
        (entry.direction === 0 ? "From " : "To ") + locationsArray.join(", ");

      let emailString = entry.email;
      let phoneString = formatter.phoneNumber(entry.phoneNumber);

      let phoneNumberRow = null;
      if (phoneString !== null && phoneString.length !== 0) {
        phoneNumberRow = (
          <Row className="my-2">
            <Col className="col-2 align-middle col-center">
              {
                <Octicon
                  mega
                  name="comment-discussion"
                  className="float-right"
                />
              }
            </Col>
            <Col className="col-10 align-middle">
              <a
                className="align-middle"
                color="link"
                href={"tel:" + phoneString}
              >
                <span>{phoneString}</span>
              </a>
            </Col>
          </Row>
        );
      }

      return (
        <Col className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 my-2">
          <Paper>
            <div
              style={{
                backgroundColor: entry.direction === 0 ? "#339999" : "#0736A4"
              }}
            >
              <div style={{ color: "#FFFFFF" }}>
                <strong>{timeString}</strong>
              </div>
            </div>
            <Container className="entry-row">
              <Row className="my-2 align-items-center h-100">
                <Col className="col-2 h-100 justify-content-center align-items-center">
                  {<Octicon mega name="location" className="float-right" />}
                </Col>
                <Col className="col-10 h-100 justify-content-center align-items-center">
                  <span className="align-middle">{locationsString}</span>
                </Col>
              </Row>

              <Row className="my-2">
                <Col className="col-2 align-middle col-center">
                  {<Octicon mega name="person" className="float-right" />}
                </Col>
                <Col className="col-10 align-middle">
                  <span className="align-middle">{name}</span>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="col-2 h-100 justify-content-center align-items-center">
                  {<Octicon mega name="mail" className="float-right" />}
                </Col>
                <Col className="col-10 h-100 justify-content-center align-items-center">
                  <a
                    className="align-middle"
                    color="none"
                    href={"mailto:" + emailString}
                  >
                    <span>{emailString}</span>
                  </a>
                </Col>
              </Row>
              {phoneNumberRow}
            </Container>
            <div>
              <Button color="link">
                <NavLink style={{ float: "left" }} to={"/matches/" + entry.id}>
                  View Matches
                </NavLink>
              </Button>
              <Button color="link" style={{ float: "right" }}>
                <NavLink to={"/edit/" + entry.id}>Edit Post</NavLink>
              </Button>
            </div>
          </Paper>
        </Col>
      );
    } else {
      return null;
    }
  }
}
