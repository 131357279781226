import React, { Component } from "react";
import Footer from "./Footer";
import ScrollToTop from "../Scrolls/ScrollToTop";
import withWindowDimensions from "./withWindowDimensions";

function BasePage(props) {
  let location = window.location.pathname;

  let width = props.windowWidth;

  let bgColor = location === "/" || width <= 460 ? "#FFFFFF" : "#e9ebee";

  return (
    <div id="top">
      <div className="base-page" style={{ backgroundColor: bgColor }}>
        {props.children}
        <div>
          <ScrollToTop />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withWindowDimensions(BasePage);
