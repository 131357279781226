import React, { Component } from "react";

import API from "../../tools/api";
//import * as auth from "../../tools/auth";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { StateContext } from "../../StateContext";
import ReactGA from "react-ga";

export default class FeedbackPopup extends Component {
  static contextType = StateContext;

  constructor() {
    super();
    this.state = {
      open: false,
      feedback: "",
      shouldUseEmail: false,
      email: "",
      returned: false,
      sent: false,
      notesFieldError: false,
      errorMessage: ""
    };
  }

  // When the component is added, fetch the student and update state
  componentDidMount() {
    ReactGA.modalview("feedback");
  }

  handleError = error => {
    this.setState({ errorMessage: "The server encountered an error" });
  };

  validate = () => {
    if (this.state.feedback.length === 0) {
      this.setState({ notesFieldError: true });
      return false;
    }
    this.setState({ notesFieldError: false });
    return true;
  };

  submit = async () => {
    if (!this.validate()) return;

    this.setState({ sent: true });
    // TODO: handle success and failure more gracefully
    try {
      let feedback = await API.sendFeedback(
        this.state.feedback,
        this.state.email
      );
      this.handleClose();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ returned: true });
    }
  };

  emailCheckboxChanged = e => {
    const [{ user }, dispatch] = this.context;
    this.setState({
      shouldUseEmail: e.target.checked,
      email: e.target.checked ? user.email : ""
    });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
      feedback: "",
      shouldUseEmail: false,
      email: "",
      returned: false,
      sent: false,
      notesFieldError: false,
      errorMessage: ""
    });
  };

  render() {
    const [{ user, loggedIn }, dispatch] = this.context;
    let open = this.state.open;

    return (
      <span>
        <Button onClick={this.handleClickOpen} style={{ color: "white" }}>
          Contact Us
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle id="alert-dialog-title">Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: 0 }}>
              Report a bug or suggest a feature:
            </DialogContentText>

            <TextField
              style={{ marginTop: 8, marginBottom: 8 }}
              id="outlined-full-width"
              multiline
              label="Notes"
              rows="4"
              rowsMax="8"
              fullWidth
              error={this.state.notesFieldError}
              value={this.state.feedback}
              onChange={e => {
                this.setState({ feedback: e.target.value });
              }}
              variant="outlined"
            />
            {loggedIn ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.shouldUseEmail}
                    onChange={this.emailCheckboxChanged}
                    value="shouldUseEmail"
                  />
                }
                label="Include email for follow up"
              />
            ) : (
              <TextField
                id="outlined-email-input"
                label="Email"
                type="email"
                name="email"
                fullWidth
                autoComplete="email"
                helperText="Optional - useful for follow ups"
                variant="outlined"
                onChange={e => {
                  this.setState({ email: e.target.value });
                }}
              />
            )}

            <DialogActions>
              <Button
                variant="outlined"
                onClick={this.handleClose}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={this.submit}
                color="primary"
                autoFocus
              >
                Submit
              </Button>
            </DialogActions>

            {this.state.sent && !this.state.returned && (
              <Grid item>Loading...</Grid>
            )}
            {this.state.returned && <Grid item>{this.state.errorMessage}</Grid>}
          </DialogContent>
        </Dialog>
      </span>
    );
  }
}
