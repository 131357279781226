import React from "react";
//import scrollIcon from "../Icons/anchor-scroll-icon.svg";
import chevronIcon from "../../Icons/chevron-double-down.svg";
import { Link } from "react-scroll";
import Icon from "@material-ui/core/Icon";

import API from "../../tools/api";

export default function AnchorScroll(props) {
  return (
    <Link
      activeClass="active"
      to="steps-section"
      spy={true}
      smooth={true}
      offset={API.IS_UNDER_CONSTRUCTION ? -150 : -70}
      duration={500}
    >
      <Icon>
        <img
          className="chevron-icon"
          src={chevronIcon}
          className="scroll-bounce chevron-icon"
          style={{ width: "60px", height: "60px" }}
          alt="Scroll down"
        />
      </Icon>
    </Link>
  );
}
