import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import { duke } from "./schoolThemes";

const theme = duke;

export default class DisabledSchoolOption extends Component {
  constructor() {
    super();
    this.state = {
      hover: false
    };
  }

  handleMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    let style = {
      backgroundColor: this.props.color,
      filter: "brightness(80%)",
      textDecoration: "none"
    };

    return (
      <div className="card-container">
        <div className="card" style={style}>
          <Typography
            variant="h5"
            style={{
              color: "#FFFFFF",
              textDecoration: "none"
            }}
          >
            {this.props.title}
          </Typography>
          <div style={{ color: "white" }}>Coming Soon!</div>
        </div>
      </div>
    );
  }
}
