export const duke = {
  palette: {
    primary: {
      main: "#339999"
    },
    secondary: {
      main: "#0736A4"
    },
    background: {
      default: "#0736A4"
    }
  }
};

export const unc = {
  palette: {
    primary: {
      main: "#4B9CD3"
    },
    secondary: {
      main: "#13294B"
    },
    background: {
      default: "#13294B"
    }
  }
};

export const ncState = {
  palette: {
    primary: {
      main: "#CC0000"
    },
    secondary: {
      main: "#000000"
    },
    background: {
      default: "#000000"
    }
  }
};
