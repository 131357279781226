import React, { Component } from "react";
import { Redirect } from "react-router";

import API from "../tools/api";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

import PasswordField from "../controls/auth/PasswordField";

import Grid from "@material-ui/core/Grid";

const StyledButton = withStyles({
  label: {
    textTransform: "capitalize"
  }
})(Button);

export default class resetPassword extends Component {
  constructor() {
    super();
    this.state = {
      newPassword: "",
      errorMessage: null,
      isLoading: false,
      showPasswordError: false,
      redirect: false
    };
  }

  handleError = error => {
    if (error.status === 401) {
      this.setState({ errorMessage: "Incorrect credentials" });
    } else {
      this.setState({ errorMessage: "The server encountered an error" });
    }
  };

  submit = async () => {
    let { newPassword } = this.state;

    if (newPassword.length === 0) {
      this.setState({ showPasswordError: true });
      return;
    }
    this.setState({ showPasswordError: false });

    try {
      this.setState({ loading: true });
      let response = await API.resetPassword(
        this.props.match.params.resetKey,
        this.state.newPassword
      );
      this.setState({ errorMessage: null, redirect: true });
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/" />;
    }

    return (
      <div>
        <center>
          <div className="page-header">Reset Your Password</div>
        </center>
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={6} lg={4} justify="center">
            <Paper className="col-11 mx-auto my-3 py-2" elevation={3}>
              <Typography>Enter your new password</Typography>
              <center>
                <PasswordField
                  autoFocus
                  fullWidth
                  id="standard-required"
                  label="New Password"
                  value={this.state.newPassword}
                  onChange={evt => {
                    this.setState({ newPassword: evt.target.value });
                  }}
                  error={this.state.showPasswordError}
                />
              </center>
              <center>
                <Button
                  disableFocusRibble
                  onClick={this.props.history.goBack}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.submit}
                  color="primary"
                  disableFocusRibble
                >
                  Submit
                </Button>
              </center>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
