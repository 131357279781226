import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import API from "../tools/api";
import Grid from "@material-ui/core/Grid";

import Warning from "@material-ui/icons/Warning";
import SvgIcon from "@material-ui/core/SvgIcon";

export default class ProfileInfo extends Component {
  constructor() {
    super();
    this.state = {
      responseMessage: null
    };
  }

  handleError = error => {
    if (error !== null && error.hasOwnProperty("data")) {
      if (error.data.hasOwnProperty("Message")) {
        this.setState({ responseMessage: error.data.Message });
      } else if (error.data.hasOwnProperty("msg")) {
        this.setState({ responseMessage: error.data.msg });
      }
    } else {
      this.setState({
        responseMessage: "The server encountered an error. Please try again."
      });
    }
  };

  resendEmail = async () => {
    try {
      const response = await API.sendEmailConfirmation();
      this.setState({
        responseMessage: response.Message
      });
    } catch (err) {
      //console.log(err);
      this.handleError(err);
    } finally {
    }
  };

  render() {
    const { name, email, phoneNumber, confirmed } = this.props.user;

    let responseLabel = null;
    if (!confirmed) {
      responseLabel = (
        <Grid
          container
          style={{
            lineHeight: "1.5rem"
          }}
          className="please-confirm"
        >
          <Grid item xs={1}>
            <center>
              <SvgIcon style={{ paddingTop: "0.1em", height: "1.5rem" }}>
                <Warning />
              </SvgIcon>{" "}
            </center>
          </Grid>
          <Grid item xs={10}>
            You have not yet confirmed your email. You must confirm before
            adding a trip.{" "}
            <span className="link" onClick={this.resendEmail}>
              Resend Confirmation Email
            </span>
          </Grid>
        </Grid>
      );
    }

    if (this.state.responseMessage !== null) {
      responseLabel = (
        <div
          style={{
            lineHeight: "1.5rem",
            fontSize: "0.9rem"
          }}
        >
          {this.state.responseMessage}
        </div>
      );
    }

    return (
      <div>
        <div
          style={{
            lineHeight: "2.5rem",
            marginBottom: "2.5rem"
          }}
        >
          {renderInfo("Name", name)}
          {renderInfo("Email", email)}
          {renderInfo("Phone number", phoneNumber)}
        </div>
        {responseLabel}
      </div>
    );
  }
}

function renderInfo(name, value) {
  return (
    <div>
      <b>{name}</b>: {value}
    </div>
  );
}
