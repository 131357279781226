import React, { Component } from "react";
import "../landing-page.css";
import { Spring } from "react-spring/renderprops";
import mainPic from "../Icons/Duke/me-you-rdu-main.svg";

export default function MainGraphic(props) {
  return (
    <Spring
      config={{ duration: 1100 }}
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
    >
      {props => (
        <div style={props}>
          <img
            src={mainPic}
            alt="Me You RDU"
            className="new-landing-page-graphic"
          />
        </div>
      )}
    </Spring>
  );
}
