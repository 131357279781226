import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import Credits from "./Credits";
import FeedbackPopup from "./FeedbackPopup";
import ScrollToTop from "../Scrolls/ScrollToTop";

export default function(props) {
  return (
    <div
      className="pt-3 pb-3 app-footer"
      style={{
        verticalAlign: "middle",
        display: "table",
        position: "relative"
      }}
    >
      <div
        style={{
          display: "table-cell",
          verticalAlign: "middle",
          textDecoration: "none"
        }}
      >
        Feature requests or bug reports?{"  "}
        <FeedbackPopup />
        <br />
        <Credits />
      </div>
    </div>
  );
}
