import React, { Component } from "react";

import DateTime from "react-datetime";

//Props to be received: startTimeValidator, isValidDate

export default class DateSelection extends Component {
  state = { date: "" };

  handleChange = (event, value) => {
    this.setState({ date: value });
  };

  render() {
    return (
      <DateTime
        classname="align-middle"
        name="date"
        displayTimeZone="America/New_York"
        id="date"
        timeFormat={false}
        input={false}
        value={this.startTimeValidator}
        isValidDate={this.props.isValidDate}
        onChange={this.handleChange}
      />
    );
  }
}
