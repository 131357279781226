import React, { Component } from "react";
import PropTypes from "prop-types";

import moment from "moment-timezone";
import DateTime from "react-datetime";

export default class TimeSelection extends Component {
  state = { time: null };

  handleChange(event) {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  handleStartTimeChange = startTime => {
    if (startTime instanceof moment) {
      this.internalHandleChange(
        "startTime",
        startTime.tz("America/New_York").unix()
      );
      if (startTime.isAfter(moment.unix(this.state.entry.endTime))) {
        let newEndTime = startTime.add(15, "minutes");
        this.handleEndTimeChange(newEndTime);
      }
    }
  };

  handleEndTimeChange = endTime => {
    if (endTime instanceof moment) {
      if (endTime.isAfter(moment.unix(this.state.entry.startTime))) {
        this.internalHandleChange(
          "endTime",
          endTime.tz("America/New_York").unix()
        );
      } else {
        this.internalHandleChange("endTime", this.state.entry.endTime);
      }
    }
  };

  sThatDay = datetime => {
    let datetimeMoment = moment.unix(datetime);
    if (datetimeMoment.isValid()) {
      let s = datetimeMoment.unix();
      let sPerDay = 86400;
      let beginning = s - (s % sPerDay);

      return s - beginning;
    } else {
      return 0;
    }
  };

  isFutureDate = function(current, selected) {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  isValidEndTime = function(currentDate, selectedDate) {
    let startTime = moment.unix(this.state.entry.startTime);
    return currentDate.isAfter(startTime);
  };

  render() {
    let time = moment.unix(this.state.time);

    return (
      <div type={this.props.type} value={time}>
        <DateTime
          displayTimeZone="America/New_York"
          timeConstraints={{ minutes: { step: 5 } }}
          dateFormat={false}
          input={false}
          value={time.isValid() ? time : null}
          onChange={this.handleTimeChange}
        />
      </div>
    );
  }
}

TimeSelection.propTypes = {
  type: PropTypes.oneOf(["start", "end"])
};
