import React, { Component } from "react";

import moment from "moment-timezone";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default class TimeConfirmationPopup extends Component {
  render() {
    let duration = moment.duration(
      (this.props.endTime - this.props.startTime) * 1000
    );

    let displayString;

    if (duration.asHours() < 0.5) {
      displayString =
        "We noticed that your pick-up time window is less than 30 minutes. A larger window increases your chances of a match.";
    } else {
      let durationString =
        Math.trunc(duration.asHours()) +
        " hrs and " +
        (duration.asMinutes() % 60) +
        " mins";

      let startTimeMoment = moment.unix(this.props.startTime);
      let endTimeMoment = moment.unix(this.props.endTime);

      displayString = `We noticed that your pick-up time window is abnormally large (${durationString}). Please confirm that you meant to set it from ${startTimeMoment.format(
        "LT"
      )}
      to ${endTimeMoment.format("LT")}.`;
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm pick-up window
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {displayString}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeHandler} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.props.confirmHandler} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
