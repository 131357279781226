import React, { Component } from "react";
import { Row } from "reactstrap";
import { Redirect } from "react-router";
import "../landing-page.css";
import Button from "@material-ui/core/Button";

export default class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = { redirect: false };
  }

  handleOnClick = () => {
    this.setState({ redirect: true });
  };

  render() {
    let centerStyleDict = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    };
    if (this.state.redirect) {
      return <Redirect push to="/create" />;
    }

    return (
      <div style={{ backgroundColor: "#0736a4" }}>
        <div className="main-container container-max">
          <img
            src={require("../Icons/Duke/me-you-rdu-main.svg")}
            alt="Me You RDU"
            className="landing-page-graphic"
            style={{
              PADDINGTOP: "70%",
              PADDINGBOTTOM: "70%"
            }}
          />
          <br />

          <div className="container container-max">
            <div className="row">
              <div className="col-12">
                <img
                  src={require("../Icons/swirly-divider-top.svg")}
                  alt="Divider"
                  className="center-block divider"
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-12">
                <div className="how-it-works">
                  <center>
                    <b>
                      <big>How it works:</big>
                    </b>
                  </center>
                </div>
              </div>
            </div>

            <br />

            <div className="dotted-line" />

            <br />
          </div>

          <div className="container container-max">
            <div className="row">
              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/1.svg")}
                  alt="Step 1"
                  className="number-icon"
                />
              </div>

              <div className="col-6">
                <div className="landing-page-text">
                  <center>
                    <b>
                      <big>Fill out the form </big>
                    </b>
                    <br />
                    with pick-up time and location
                  </center>
                </div>
              </div>

              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/form.svg")}
                  alt="Fill in form"
                  className="graphic-icon"
                />
              </div>
            </div>

            <br />

            <div className="dotted-line" />

            <br />

            <div className="row">
              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/match.svg")}
                  alt="Match"
                  className="center-block graphic-icon"
                />
              </div>

              <div className="col-6">
                <div className="center-block">
                  <div className="landing-page-text">
                    <center>
                      <b>
                        <big>Get matched</big>
                      </b>
                      <br />
                      to potential carpoolers
                    </center>
                  </div>
                </div>
              </div>

              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/2.svg")}
                  alt="Step 2"
                  className="center-block number-icon"
                />
              </div>
            </div>

            <br />

            <div className="dotted-line" />

            <br />

            <div className="row">
              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/3.svg")}
                  alt="Step 3"
                  height="25%"
                  width="25%"
                  className="center-block number-icon"
                />
              </div>

              <div className="col-6">
                <div className="landing-page-text">
                  <center>
                    <b>
                      <big>Check your email</big>
                    </b>
                    <br />
                    for your matches and get notified as new matches arise
                  </center>
                </div>
              </div>

              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/mail.svg")}
                  alt="Email notification"
                  className="center-block graphic-icon"
                />
              </div>
            </div>

            <br />

            <div className="dotted-line" />

            <br />

            <div className="row">
              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/car.svg")}
                  alt="Car"
                  className="center-block graphic-icon"
                />
              </div>

              <div className="col-6">
                <div className="center-block">
                  <div className="landing-page-text">
                    <center>
                      <b>
                        <big>Contact your best match</big>
                      </b>
                      <br />
                      to coordinate your carpool/Lyft/Uber
                    </center>
                  </div>
                </div>
              </div>

              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/4.svg")}
                  alt="Step 4"
                  className="center-block number-icon"
                />
              </div>
            </div>

            <br />

            <div className="dotted-line" />

            <br />

            <div className="row">
              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/5.svg")}
                  alt="Step 5"
                  className="center-block number-icon"
                />
              </div>

              <div className="col-6">
                <div className="save-money">
                  <center>SAVE MONEY!</center>
                </div>
              </div>

              <div className="col-3" style={centerStyleDict}>
                <img
                  src={require("../Icons/pot of gold.svg")}
                  alt="Pot of gold"
                  className="center-block graphic-icon"
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-12">
                <img
                  src={require("../Icons/swirly-divider-bottom.svg")}
                  alt="Divider"
                  className="center-block divider"
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <Row className="justify-content-center mt-2">
            <Button
              variant="contained"
              className="justify-content-center btn-lg {classes.button}"
              onClick={this.handleOnClick}
              style={{
                backgroundColor: "#339999"
              }}
            >
              <strong style={{ color: "#FFFFFF" }}>Get Started</strong>
            </Button>
          </Row>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}
