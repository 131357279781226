import React, { Component } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SchoolInfo from "../../tools/schoolInfo";

export default class DirectionSelection extends Component {
  state = {
    direction: 0,
    origins: ""
  };

  handleOriginsChange = evt => {
    let value = evt.target.id;

    let originsString = this.state.origins;
    var origins = originsString.split(",");

    var index = origins.indexOf(value);
    if (index > -1) {
      origins.splice(index, 1);
    } else {
      origins.push(value);
    }
    var newOriginsString = origins.join(",");
    if (newOriginsString.charAt(0) === ",") {
      newOriginsString = newOriginsString.substr(1);
    }
    this.setState({ origins: newOriginsString });
  };

  handleDirectionChange = evt => {
    let value = evt.target.value;
    this.setState({ direction: value });
  };

  originsIncludes = origin => {
    let originsString = this.state.origins;
    var origins = originsString.split(",");
    var index = origins.indexOf(origin);
    return index > -1;
  };

  render() {
    let origins = SchoolInfo.dukeLocationsMap;

    let directionColor = this.state.direction === 0 ? "primary" : "secondary";

    let originOptions = Object.keys(origins).map(key => {
      return (
        <FormControlLabel
          control={
            <Checkbox
              id={key}
              name={origins[key]}
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
              checked={this.originsIncludes(key)}
              onChange={this.handleOriginsChange}
              value={origins[key]}
              color={directionColor}
            />
          }
          label={origins[key]}
        />
      );
    });

    return (
      <div value={this.state}>
        <Tabs
          value={this.state.direction}
          center
          indicatorColor={directionColor}
          textColor={directionColor}
          onChange={this.handleDirectionChange}
          style={{
            overflowX: "hide"
          }}
        >
          <Tab label="Duke → RDU" className="disable-focus" value={0} />
          <Tab label="RDU → Duke" className="disable-focus" value={1} />
        </Tabs>
        <FormControl component="fieldset">
          <FormGroup>{originOptions}</FormGroup>
        </FormControl>
      </div>
    );
  }
}
