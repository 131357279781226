var cookie = require("react-cookies");

export function storeAuthResponse(authResponse) {
  const expiryDate = new Date();
  expiryDate.setDate(Date.now() + authResponse.jwt.expiration_time_seconds);

  setJWT(authResponse.jwt, expiryDate);
  setUser(authResponse.user, expiryDate);
}

function setJWT(jwt, expiryDate) {
  cookie.save("jwt", jwt, {
    path: "/",
    expiryDate
  });
}

function setUser(user, expiryDate) {
  cookie.save("user", user, {
    path: "/",
    expiryDate
  });
}

export function getUser() {
  return cookie.load("user");
}

export function getJWT() {
  return cookie.load("jwt");
}

export function isLoggedIn() {
  return cookie.load("jwt") !== undefined;
}

function safelyRemoveCookie(cookieName) {
  if (cookie.load(cookieName) !== undefined) {
    cookie.remove(cookieName, { path: "/" });
  }
}

export function logout() {
  safelyRemoveCookie("user");
  safelyRemoveCookie("jwt");
}

export default { storeAuthResponse, getUser, getJWT, isLoggedIn, logout };
