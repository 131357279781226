import React, { Component } from "react";

export default class Credits extends Component {
  shuffle = array => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  render() {
    var names = this.shuffle([
      { name: "Cooper Edmunds", link: "https://github.com/CooperEdmunds" },
      { name: "Yasa Baig", link: "https://github.com/yasab27" },
      { name: "Nicole Moiseyev", link: "https://github.com/nicolemoiseyev" }
    ]);

    return (
      <small>
        {"Created by "}
        <a className=" credit-link" href={names[0].link}>
          {names[0].name}
        </a>
        {", "}
        <a className="credit-link" href={names[1].link}>
          {names[1].name}
        </a>
        {", "}
        <a className="credit-link" href={names[2].link}>
          {names[2].name}
        </a>
      </small>
    );
  }
}
