import api from "../axiosClient";
import auth from "../auth";

// Authentication
export async function login(email, password) {
  let credentials = { email: email.toLowerCase(), password: password };
  let { data } = await api.post("login", credentials);
  auth.storeAuthResponse(data);
  return data;
}

export async function register(user) {
  let userCopy = JSON.parse(JSON.stringify(user));
  userCopy.email = userCopy.email.toLowerCase();
  if (!userCopy.hasOwnProperty("phoneNumber")) {
    userCopy.phoneNumber = "";
  }

  let { data } = await api.post("user/register", userCopy);
  auth.storeAuthResponse(data);
  return data;
}

export async function requestPasswordReset(email) {
  let body = { email: email.toLowerCase() };
  let { data } = await api.post("/user/reset/request", body);
  return data;
}

export async function resetPassword(resetKey, newPassword) {
  let body = { newPassword: newPassword };
  let { data } = await api.post(`/user/reset`, body, {
    params: {
      key: resetKey
    }
  });
  return data;
}

export async function confirmEmail(key) {
  let { data } = await api.post(`/user/confirm?key=${key}`);
  auth.storeAuthResponse(data);
  return data;
}

export async function sendEmailConfirmation() {
  let { data } = await api.post(`/user/reconfirm`);
  return data;
}

export async function updateProfile(profile) {
  let { data } = await api.put("/user", profile);
  auth.storeAuthResponse(data);
  return data;
}

export async function refreshUser() {
  let { data } = await api.post("/user/refresh");
  auth.storeAuthResponse(data);
  return data;
}
