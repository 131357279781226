import React, { Component } from "react";

import API from "../../tools/api";
import { Row, Col } from "reactstrap";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import DialogContentText from "@material-ui/core/DialogContentText";
import PasswordField from "./PasswordField";

import Spinner from "../Spinner";

import ReactGA from "react-ga";

import { withStyles } from "@material-ui/core/styles";

import { StateContext } from "../../StateContext";

const StyledButton = withStyles({
  label: {
    textTransform: "capitalize"
  }
})(Button);

export default class LoginPopup extends Component {
  static contextType = StateContext;

  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      serverErrorMessage: null,
      isLoading: false,
      showPassword: false
    };
  }

  handleError = error => {
    if (error !== null && error.hasOwnProperty("data")) {
      if (error.data.hasOwnProperty("Message")) {
        this.setState({ serverErrorMessage: error.data.Message });
      } else if (error.data.hasOwnProperty("msg")) {
        if(error.data.msg.includes("token")){
          this.setState({serverErrorMessage:"Authentication error. Please log-in again."})
        } else {
          this.setState({ serverErrorMessage: error.data.msg });
        }
      }
    }

    if (this.state.serverErrorMessage === null) {
      this.setState({
        serverErrorMessage: "The server encountered an error. Please try again."
      });
    }
  };

  login = async () => {
    const [{ user }, dispatch] = this.context;
    this.setState({ isLoading: true });
    let gotError = false;
    try {
      let authResponse = await API.login(this.state.email, this.state.password);
      dispatch({
        type: "handleAuth",
        authResponse: authResponse
      });
    } catch (err) {
      gotError = true;
      console.log(err);
      this.handleError(err);
    } finally {
      this.setState({ isLoading: false });
      if (!gotError) this.props.closeHandler();
    }
  };

  render() {
    if (this.props.open) {
      ReactGA.modalview("login");
    }

    let finalActionButtonsRow = (
      <DialogActions>
        <Button
          variant="outlined"
          onClick={this.props.closeHandler}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={this.login}
          color="primary"
          autoFocus
        >
          Log in
        </Button>
      </DialogActions>
    );

    if (this.state.isLoading) {
      finalActionButtonsRow = (
        <center>
          <Spinner spinnerClass="small-spinner-bottom" />
        </center>
      );
    }

    let errorLabel = null;
    if (this.state.serverErrorMessage !== null) {
      let errorMessage = this.state.serverErrorMessage;
      errorLabel = (
        <Row className="my-2">
          <Col className="col-12 justify-content-center">
            <p className="text-center error-label">{errorMessage}</p>
          </Col>
        </Row>
      );
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter your email and password.</DialogContentText>
          <TextField
            autoFocus
            id="standard-required"
            fullWidth
            label="Email"
            value={this.state.email}
            onChange={evt => {
              this.setState({ email: evt.target.value });
            }}
            error={false}
            inputProps={{ autoComplete: "email" }}
          />
          <PasswordField
            fullWidth
            value={this.state.password}
            onChange={evt => {
              this.setState({ password: evt.target.value });
            }}
          />

          <center>
            <StyledButton
              color="primary"
              onClick={this.props.switchToForgotPassword}
            >
              Forgot password
            </StyledButton>
            |
            <StyledButton color="primary" onClick={this.props.switchToRegister}>
              Go to sign up
            </StyledButton>
          </center>
        </DialogContent>
        {errorLabel}
        {finalActionButtonsRow}
      </Dialog>
    );
  }
}
